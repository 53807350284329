import { Chip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";

import { ITagListResponse } from "@/api/types/tag";

export const tagListColumnSchema: GridColDef[] = [
    {
        field: "id",
        headerName: "ID",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
    },
    {
        field: "ml_title.en",
        headerName: "English Title",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
        renderCell(params: GridRenderCellParams<ITagListResponse>) {
            return params.row.ml_title.en;
        },
    },
    {
        field: "ml_title.ar",
        headerName: "Arabic Title",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
        renderCell(params: GridRenderCellParams<ITagListResponse>) {
            return params.row.ml_title.ar;
        },
    },
    {
        field: "slug",
        headerName: "Slug",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
    },
    {
        field: "category",
        headerName: "Category",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
        renderCell(params: GridRenderCellParams<ITagListResponse>) {
            if (params.row.isEducation) return <Chip color="success" label="Education" size="small" variant="outlined" />;
            if (params.row.isEntertainment)
                return <Chip color="info" label="Entertainment" size="small" variant="outlined" />;
        },
    },
    {
        field: "updatedAt",
        headerName: "Updated At",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: true,
        renderCell(params: GridRenderCellParams<ITagListResponse>) {
            return dayjs(params.row.updatedAt).format("YYYY-MM-DD HH:mm");
        },
    },
];
