import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";

import { createStaticValidationSchema } from "./validation";
import { IStaticCreateFormType } from "./form-type";
import { staticSchema } from "./schema";

import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import FormGenerator from "@/components/kit/FormGenerator";
import { staticService } from "@/api/services/static";
import { getAxiosError } from "@/utils/get-axios-error";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { generateDefaultValueFromFormSchema } from "@/utils/generate-default-value-from-form-schema";
import { IParams } from "@/types/params";
import { slugify } from "@/utils/slugify";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";

const StaticForm: React.FC = () => {
    const { id } = useParams<IParams>();
    const isEditingRule = Boolean(id);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(isEditingRule);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const listAccess = useCheckPermissionAccess("static", "List");

    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
        reset,
        setValue,
        watch,
    } = useForm<IStaticCreateFormType>({
        defaultValues: generateDefaultValueFromFormSchema(staticSchema),
        mode: "all",
        resolver: yupResolver(createStaticValidationSchema),
    });

    const getEditData = async () => {
        try {
            setLoading(true);
            const response = await staticService.show(Number(id));
            const { ml_title, slug, ml_html } = response.data.data;

            reset({
                en_title: ml_title.en,
                ar_title: ml_title.ar,
                slug,
                en_html_content: ml_html.en,
                ar_html_content: ml_html.ar,
            });
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";

            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    const onSubmit = async (_val: IStaticCreateFormType) => {
        try {
            setSubmitLoading(true);
            const payload = {
                ml_title: { en: _val.en_title, ar: _val.ar_title },
                slug: _val.slug,
                ml_html: {
                    en: _val.en_html_content,
                    ar: _val.ar_html_content,
                },
            };

            if (isEditingRule) {
                await staticService.update(Number(id), payload);
            } else {
                await staticService.create(payload);
            }
            enqueueSnackbar(`${isEditingRule ? "Static Page updated" : "New Static Page created"} successfully`, {
                variant: "success",
            });
            navigate(ROUTE_CONSTANTS.SETTINGS.STATIC.ROOT.ABSOLUTE);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";

            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setSubmitLoading(false);
        }
    };

    useEffect(() => {
        isEditingRule && getEditData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditingRule]);

    useEffect(() => {
        const subscription = watch((values, event) => {
            if (event.name === "en_title") {
                setValue("slug", slugify(values.en_title || ""));
            }
        });

        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    const footer = (
        <Stack direction={"row"} paddingBottom={10} spacing={2}>
            <LoadingButton disabled={!isValid || submitLoading} loading={submitLoading} type="submit" variant="contained">
                {isEditingRule ? "Update" : "Create"} Static page
            </LoadingButton>
            <Button type="button" onClick={() => navigate(-1)}>
                Cancel
            </Button>
        </Stack>
    );

    return (
        <>
            <DashboardHeader
                title={`${isEditingRule ? "Update" : "Create New"} Static page`}
                {...(listAccess
                    ? {
                          titleSuffix: (
                              <Button component={Link} to={ROUTE_CONSTANTS.SETTINGS.STATIC.ROOT.ABSOLUTE}>
                                  Back To Static Page List
                              </Button>
                          ),
                      }
                    : undefined)}
            />
            <DashboardContent>
                <FormGenerator
                    control={control}
                    errors={errors}
                    footer={footer}
                    loading={loading}
                    schema={staticSchema}
                    onSubmit={handleSubmit(onSubmit)}
                />
            </DashboardContent>
        </>
    );
};

export default StaticForm;
