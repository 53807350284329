import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";
import { FormHelperText, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

import { TextFieldSchema } from "@/types/form-generator-schema-type";
import { SelectOptionItem } from "@/types/select-option";

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label?: string;
    placeholder: string;
    data?: SelectOptionItem[];
    className?: string;
    errorMessage: FieldError;
    props?: TextFieldSchema["props"];
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const FormMultiSelect = ({ field, label, placeholder, data, className, errorMessage, props = {} }: IProps) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = event.target;

        field.onChange(typeof value === "string" ? value.split(",") : value);
    };

    return (
        <FormControl fullWidth className={className} margin="normal" variant="outlined">
            <TextField
                select
                id={`select-label-${field.name}`}
                label={label}
                {...field}
                {...props}
                SelectProps={{
                    multiple: true,
                    native: false,
                    MenuProps,
                    variant: "outlined",
                    renderValue: (selected) => {
                        return data
                            ?.filter((item) => (Array.isArray(selected) ? selected.includes(Number(item.value)) : []))
                            .map((item) => item.label)
                            .join(", ");
                    },
                }}
                defaultValue={field.value || []}
                placeholder={placeholder}
                value={field.value || []}
                onChange={handleChange}
            >
                {data?.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                        <Checkbox checked={field.value?.indexOf(value as string) > -1} />
                        <ListItemText primary={label} />
                    </MenuItem>
                ))}
            </TextField>

            {errorMessage?.message ? <FormHelperText error>{errorMessage?.message}</FormHelperText> : null}
        </FormControl>
    );
};

export default FormMultiSelect;
