import withCheckPermissions from "@/layouts/GeneralLayoutWrapper/withCheckPermissions";
import VideoList from "@/pages/MediaManagement/Video";
import VideoFormAdd from "@/pages/MediaManagement/Video/AddVideo";
import VideoFormUpdate from "@/pages/MediaManagement/Video/EditVideo";
import VideoDetails from "@/pages/MediaManagement/Video/Show";
import ImageList from "@/pages/MediaManagement/Image";
import ImageForm from "@/pages/MediaManagement/Image/AddUpdate";
import AvatarList from "@/pages/MediaManagement/Avatar";
import AvatarForm from "@/pages/MediaManagement/Avatar/AddUpdate";

export const ProtectedVideoList = withCheckPermissions(VideoList, "video", "List");
export const ProtectedVideoUpdate = withCheckPermissions(VideoFormUpdate, "video", "Update");
export const ProtectedVideoCreate = withCheckPermissions(VideoFormAdd, "video", "Create");
export const ProtectedVideoDetails = withCheckPermissions(VideoDetails, "video", "Detail");

export const ProtectedImageList = withCheckPermissions(ImageList, "image", "List");
export const ProtectedImageForm = withCheckPermissions(ImageForm, "image", "Create");

export const ProtectedAvatarList = withCheckPermissions(AvatarList, "image", "List");
export const ProtectedAvatarCreate = withCheckPermissions(AvatarForm, "image", "Create");
