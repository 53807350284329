import { Data } from "../types";

import { HeadCell } from "@/components/app/EnhancedTableHeader/type";

const headCells: HeadCell<Data>[] = [
    {
        id: "id",
        numeric: true,
        disablePadding: true,
        label: "ID",
    },
    {
        id: "title",
        numeric: false,
        disablePadding: true,
        label: "Title",
    },
    {
        id: "createdAt",
        numeric: false,
        disablePadding: true,
        label: "Created At",
    },
    {
        id: "updatedAt",
        numeric: false,
        disablePadding: true,
        label: "Updated At",
    },
];

export default headCells;
