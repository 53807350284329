import { CircularProgress, Typography } from "@mui/material";

import { LoadingStyled } from "./styled";

import LogoWhite from "@/components/app/LogoWhite";

const InitLoading = ({ height = "100vh", text }: { height?: string, text?: string }) => {
    return (
        <LoadingStyled style={{ height, display: "flex" }}>
            <center>
                <div className="logo">
                    <LogoWhite />
                </div>
                <CircularProgress />
                <Typography>{text}</Typography>
            </center>
        </LoadingStyled>
    );
};

export default InitLoading;
