import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";
import { FormControl, FormHelperText, MenuItem, TextField } from "@mui/material";

import { SelectOptionItem } from "@/types/select-option";
import { TextFieldSchema } from "@/types/form-generator-schema-type";

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label?: string;
    placeholder: string;
    data?: SelectOptionItem[];
    className?: string;
    errorMessage: FieldError;
    props?: TextFieldSchema["props"];
}

const FormSelect = ({ field, label, placeholder, data, className, errorMessage, props = {} }: IProps) => {
    return (
        <FormControl fullWidth className={className} margin="normal" variant="outlined">
            <TextField select {...field} defaultValue={field.value} {...props} label={label} placeholder={placeholder}>
                {data?.map(({ label, value }, index) => (
                    <MenuItem key={index} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </TextField>
            {errorMessage?.message ? <FormHelperText error>{errorMessage?.message}</FormHelperText> : null}
        </FormControl>
    );
};

export default FormSelect;
