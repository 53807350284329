import { CircularProgress } from "@mui/material";

import { TableLoadingStyled } from "./styled";

const TableLoading = () => {
    return (
        <TableLoadingStyled>
            <center>
                <CircularProgress />
            </center>
        </TableLoadingStyled>
    );
};

export default TableLoading;
