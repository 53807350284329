import { number, object, string } from "yup";

import { UI_SECTION } from "@/constants/enums/home-page-ui";

export const createUiSectionValidationSchema = object().shape({
    type: string().required("Type is required"),
    tag: number().when("type", {
        is: UI_SECTION.CONTINUE,
        then: (schema) =>
            schema
                .notRequired()
                .nullable()
                .transform((value, originalValue) => (originalValue === "" ? 0 : value)),
        otherwise: (schema) => schema.required("Tag is required"),
    }),
    itemCount: number().required("itemCount is required"),
    ml_title_en: string()
        .nullable()
        .notRequired()
        .transform((value, originalValue) => (originalValue === "" ? null : value))
        .test("minLength", "English title must be at least 3 characters", (value) => !value || value.length >= 3)
        .test("maxLength", "English title must be at most 80 characters", (value) => !value || value.length <= 80),
    ml_title_ar: string()
        .nullable()
        .notRequired()
        .transform((value, originalValue) => (originalValue === "" ? null : value))
        .test("minLength", "Arabic title must be at least 3 characters", (value) => !value || value.length >= 3)
        .test("maxLength", "Arabic title must be at most 80 characters", (value) => !value || value.length <= 80),
});
