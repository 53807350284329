import { Controller, FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

import { IVideoCreateFormType } from "./type";
import { createVideoValidationSchema } from "./validation";

import FormVideo from "@/components/kit/FormGenerator/FormVideo";
import FormText from "@/components/kit/FormGenerator/FormText";
import FormTextArea from "@/components/kit/FormGenerator/FormTextarea";
import FormSelectImage from "@/components/kit/FormGenerator/FormSelectImage";

const defaultValues: IVideoCreateFormType = {
    title: "",
    videoId: "",
    thumbnailId: 0,
    description: "",
};

export default function AddVideoForm({
    onSubmit,
    loading,
}: {
    // eslint-disable-next-line no-unused-vars
    onSubmit: (val: IVideoCreateFormType) => void,
    loading?: boolean,
}) {
    const navigate = useNavigate();

    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
    } = useForm<IVideoCreateFormType>({
        defaultValues,
        mode: "all",
        resolver: yupResolver(createVideoValidationSchema),
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                    <Controller
                        key="videoId"
                        control={control}
                        name="videoId"
                        render={({ field }) => (
                            <FormVideo
                                className="field"
                                errorMessage={errors["videoId"] as FieldError}
                                field={{ ...field }}
                                label="video"
                                placeholder="Video"
                                props={{
                                    required: true,
                                    accept: "video/*",
                                    // accept: "video/mp4,video/x-m4v,video/*"
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Controller
                        key="thumbnailId"
                        control={control}
                        name="thumbnailId"
                        render={({ field }) => (
                            <FormSelectImage
                                className="field"
                                errorMessage={errors["thumbnailId"] as FieldError}
                                field={{ ...field }}
                                label="Thumbnail"
                                placeholder="(Max Size: 3Mb)"
                                props={{
                                    required: true,
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Controller
                        key="title"
                        control={control}
                        name="title"
                        render={({ field }) => (
                            <FormText
                                className="field"
                                errorMessage={errors["title"] as FieldError}
                                field={{ ...field }}
                                label="Title"
                                placeholder="Enter Text "
                                props={{
                                    required: true,
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Controller
                        key="description"
                        control={control}
                        name="description"
                        render={({ field }) => (
                            <FormTextArea
                                className="field textarea"
                                errorMessage={errors["description"] as FieldError}
                                field={{ ...field }}
                                label="Description"
                                placeholder="Enter Description Text"
                                props={{
                                    required: true,
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Stack direction={"row"} paddingBottom={10} spacing={2}>
                        <LoadingButton disabled={!isValid || loading} loading={loading} type="submit" variant="contained">
                            Create Video
                        </LoadingButton>
                        <Button type="button" onClick={() => navigate(-1)}>
                            Cancel
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </form>
    );
}
