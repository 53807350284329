import { Skeleton, TableCell, TableRow } from "@mui/material";

export default function TableSkeleton({ cells }: { cells: string[] | number[] }) {
    return (
        <>
            <TableRow>
                {cells.map((num) => (
                    <TableCell key={num}>
                        <Skeleton height="50px" width="100%" />
                    </TableCell>
                ))}
                <TableCell>
                    <Skeleton height="50px" width="100%" />
                </TableCell>
            </TableRow>
            <TableRow>
                {cells.map((num) => (
                    <TableCell key={num}>
                        <Skeleton height="50px" width="100%" />
                    </TableCell>
                ))}
                <TableCell>
                    <Skeleton height="50px" width="100%" />
                </TableCell>
            </TableRow>
            <TableRow>
                {cells.map((num) => (
                    <TableCell key={num}>
                        <Skeleton height="50px" width="100%" />
                    </TableCell>
                ))}
                <TableCell>
                    <Skeleton height="50px" width="100%" />
                </TableCell>
            </TableRow>
            <TableRow>
                {cells.map((num) => (
                    <TableCell key={num}>
                        <Skeleton height="50px" width="100%" />
                    </TableCell>
                ))}
                <TableCell>
                    <Skeleton height="50px" width="100%" />
                </TableCell>
            </TableRow>
        </>
    );
}
