import type {
    IGetCountriesMiniListBody,
    IPutCountryByIdBody,
    IAddCountryByIdBody,
    IGetCountriesListParams,
} from "@/api/types/countries";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
    GetCountryById,
    UpdateCountryById,
    DeleteCountry,
    CreateCountry,
    GetCountries,
    GetCountriesMiniList,
} from "@/api/services/countries";

export function useGetCountryById(id: number) {
    return useQuery({
        queryKey: ["country", id],
        queryFn: () => GetCountryById(id),
    });
}

// ? these APIs are using the POST method for the most part.
// ? so having a cache in TanStackQuery is little bit a JOKE

export function useGetCountries(params: IGetCountriesListParams) {
    return useQuery({ queryKey: ["country", params], queryFn: () => GetCountries(params) });
}

export function useUpdateCountry() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ id, body }: { id: number, body: IPutCountryByIdBody }) => UpdateCountryById(id, body),
        onSuccess: (data, { id }) => {
            queryClient.invalidateQueries({ queryKey: ["country", id] });
        },
    });
}

export function useDeleteCountry() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => DeleteCountry(id),
        onSuccess: (_, id) => {
            queryClient.invalidateQueries({ queryKey: ["country", id] });
        },
    });
}

export function useCreateCountry() {
    return useMutation({
        mutationFn: (body: IAddCountryByIdBody) => CreateCountry(body),
    });
}

export function useGetMiniList() {
    return useMutation({
        mutationFn: (body: IGetCountriesMiniListBody) => GetCountriesMiniList(body),
    });
}
