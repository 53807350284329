import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSnackbar } from "notistack";

import AddSessionsForm from "../../../forms/addSeasonForm";
import { ICreateSeasonForm } from "../../../forms/addSeasonForm/type";

import { useCreateContent, useUpdateContent } from "@/api/hooks/useContents";
import { CONTENT_TYPE } from "@/constants/enums/content-type";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { IContentShowResponse, IContentUpdatePayload } from "@/api/types/content";

interface IEpisodeModal {
    open: boolean;
    refetch?: () => void;
    onClose: () => void;
    onSuccess?: () => void;
    onSettled?: () => void;
    data: IContentShowResponse | null;
    parentId: number;
    maxNumber?: number;
    seasonNumbers: number[];
}

export default function FormModalOfSessions({
    open,
    onClose,
    onSuccess,
    onSettled,
    data,
    parentId,
    seasonNumbers,
    maxNumber = 1,
}: IEpisodeModal) {
    const { mutate: updateSerial } = useUpdateContent();
    const { mutate: createSerial } = useCreateContent();
    const { enqueueSnackbar } = useSnackbar();

    const createAccess = useCheckPermissionAccess("content", "Create");

    const handleAddSerial = (formVal: ICreateSeasonForm) => {
        if (!createAccess) {
            enqueueSnackbar("You don't have access for creating a new Session", { variant: "warning" });

            return;
        }

        if (!formVal.autoId && formVal.season && formVal.season !== data?.season && seasonNumbers.includes(formVal.season)) {
            enqueueSnackbar(`You Created a Season with the Number of ${formVal.season} Before`, { variant: "info" });

            return;
        }

        const body: IContentUpdatePayload = {
            ml_description: {
                en: formVal.en_description,
                ar: formVal.ar_description,
            },
            ml_name: {
                en: formVal.en_title,
                ar: formVal.ar_title,
            },
            parentId: Number(parentId),
            status: String(formVal.sharing_state),
            season: formVal.autoId && maxNumber ? Number(maxNumber + 1) : formVal.season,
            slug: formVal.en_title.split(" ").join("_").toLowerCase(),
            typeId: CONTENT_TYPE.SESSION,
        };

        if (data?.id) {
            updateSerial(
                { id: data?.id, body },
                {
                    onSuccess: () => onSuccess && onSuccess(),
                    onSettled: () => onSettled && onSettled(),
                }
            );
        } else {
            createSerial(body, {
                onSuccess: () => {
                    if (onSuccess) onSuccess();
                    if (onClose) onClose();
                },
            });
        }
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
            <DialogTitle> Season Form </DialogTitle>
            <DialogContent sx={{ background: "#212121" }}>
                <AddSessionsForm data={data} onSubmit={handleAddSerial} />
            </DialogContent>
        </Dialog>
    );
}
