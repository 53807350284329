import { styled } from "@mui/material";

export default styled("div")`
    width: 100%;
    display: flex;
    flex-direction: column;
    
    .textarea {
        min-height: 230px;

      
    }

    form {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .content {
        display: flex;
        flex-direction: column;
        width: 100%;

        &.row {
            display: flex;
            gap: 2em;
            flex-direction: column;
            width: 100%;
        }

        &.grid {
            display: grid;
            grid-gap: 1em;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 1em;
        }
    }
    
    .section  {
        width: 100%;
        display: flex;
        flex-direction: column;

        .title {
            display: flex;
            width: 100%;
            margin-bottom:0;
            margin-top: 2em;
        }

        &.media {
            width: 100%;
            display: flex;
            align-items: center;

            .title {
                width: 100%;
            }

            .content {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 2em;
                justify-content: space-between;

                .col  {
                    width: 100%;
                }

                .left-sec {
                    max-width: 370px;

                    .portrait-box {
                        width: 100%;
                        aspect-ratio: 2/3;

                        .portrait-thumb {
                            aspect-ratio: 16/9;
                            width: 100%;
                            height: 100%;
                            & > div {
                                height: inherit;
                            }
                        }
                    }
                }

                .right-sec {
                    .landscape-box {
                        width: 100%;
                        aspect-ratio: 16/9;
                        .landscape-thumb {
                            width: 100%;
                            height: 100%;
                            & > div {
                                height: inherit;
                            }
                        }
                    }
                    
                    .video-section {
                        display: flex;
                        flex-direction: row;
                        gap: 2em;
                        justify-content: space-between;

                        .select-trailer, .select-video {
                            width: 100%;
                        }
                    }
                }
            }
        }

        &.subtitles {

            .content {
                margin-top: 1em;
                display:flex;
                flex-direction: row;
                align-items:center;
                justify-content: flex-start;
            }
        }

        &.info {}
    }

    .actions {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;


        .sharing_state {
        }


        .loading-btn {
            min-width: 200px;
            max-height: 40px;
            margin-inline-end: 10px;
        }
    }
`;
