import { API_ROUTES } from "@/constants/api-routes";
import AxiosInstance from "@/api";
import { ITableParams } from "@/types/table";
import {
    ICustomerBlockPayload,
    ICustomerListResponse,
    ICustomerUpdatePayload,
    ICustomerUpdateResponse,
    ICustomerBlockResponse,
    ICustomerGetByIdResponse,
} from "@/api/types/customer";
import { serializeObject } from "@/utils/serializer";

export const CustomerGetList = async (params: ITableParams) => {
    const result = await AxiosInstance.get<ICustomerListResponse>(`${API_ROUTES.CUSTOMER.LIST}?${serializeObject(params)}`);

    return result.data;
};

export const CustomerUpdate = async (id: number, data: ICustomerUpdatePayload) => {
    const result = await AxiosInstance.put<ICustomerUpdateResponse>(API_ROUTES.CUSTOMER.UPDATE(id), data);

    return result.data;
};

export const CustomerBlock = async (id: number, data: ICustomerBlockPayload) => {
    const result = await AxiosInstance.put<ICustomerBlockResponse>(API_ROUTES.CUSTOMER.BLOCK(id), data);

    return result.data;
};

export const CustomerGetById = async (id: number) => {
    const result = await AxiosInstance.get<ICustomerGetByIdResponse>(API_ROUTES.CUSTOMER.SHOW(id));

    return result.data;
};
