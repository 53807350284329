import { mixed, object, string } from "yup";

const MAX_FILE_SIZE = 3 * 1024 * 1024; // 3MB
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

export const createImageValidationSchema = object().shape({
    title: string().required("Title is required"),
    image: mixed<File>()
        .required("Image is required")
        .test(
            "fileSize",
            "File size is too large, max size is 3MB",
            (value) => {
                if (!value) return false;

                return value && value?.size <= MAX_FILE_SIZE;
            } // Check file size
        )
        .test(
            "fileFormat",
            "Unsupported file format, please upload JPG or PNG",
            (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type)) // Check file type
        ),
});
