import { create } from "zustand";

import { IAuthenticatedUser, IPermissions } from "@/api/types/auth";

export interface IAuthStore {
    user: IAuthenticatedUser | null;
    userPermissions: IPermissions | null;
    actions: {
        // eslint-disable-next-line no-unused-vars
        setUser: (value: IAuthenticatedUser) => void,
        clearUser: () => void,
        // eslint-disable-next-line no-unused-vars
        setUserPermissions: (values: IPermissions) => void,
        clearUserPermissions: () => void,
    };
}

export const useAuthStore = create<IAuthStore>((set) => ({
    user: null,
    userPermissions: null,
    actions: {
        setUser: (value) => set({ user: value }),
        clearUser: () => set({ user: undefined }),
        setUserPermissions: (values) => set({ userPermissions: values }),
        clearUserPermissions: () => set({ userPermissions: null }),
    },
}));

export const useAuthActions = () => useAuthStore((store) => store.actions);
export const useUser = () => useAuthStore((store) => store.user);
export const useUserPermissions = () => useAuthStore((store) => store.userPermissions);
