import { useCallback, useMemo, useState } from "react";

import GenreHeader from "./partials/pageHeader";
import GenreFilters, { defaultValues } from "./partials/filters";
import GenreTable from "./partials/table";
import GenresStyle from "./style";
import { createData } from "./partials/helpers";

import { useGenreList } from "@/api/hooks/useGenre";
import { GenreFilterBody } from "@/api/types/genre";

export default function Genres() {
    const [params, setParams] = useState<GenreFilterBody>({
        category: null,
        search: "",
        skip: 0,
        take: 5,
    });

    const { data: genres, isError, error } = useGenreList(params);

    const totalItems = genres?.pagination.totalItems;

    const handleChange = useCallback(({ page, rowsPerPage }: { page?: number, rowsPerPage?: number }) => {
        setParams((prev) => {
            return {
                ...prev,
                skip: Number(page) * Number(rowsPerPage),
                take: rowsPerPage,
            };
        });
    }, []);

    const handleFilterOnApply = useCallback((filterData: typeof defaultValues) => {
        setParams((prev) => {
            return {
                ...prev,
                search: String(filterData.search) || "",
                category: filterData.category || null,
            };
        });
    }, []);

    const genreData = useMemo(() => (genres ? genres.items.map((item) => createData(item)) : []), [genres]);

    return (
        <GenresStyle>
            <GenreHeader />
            <GenreFilters onApply={handleFilterOnApply} />
            {isError && <p>{error.message}</p>}
            {genres && genres.items.length > 0 && (
                <GenreTable
                    data={genreData}
                    page={Number(params.skip) / Number(params.take)}
                    rowsPerPage={Number(params.take)}
                    totalItems={totalItems || 0}
                    onChange={handleChange}
                />
            )}
        </GenresStyle>
    );
}
