import { styled } from "@mui/material";

export const UserFiltersStyle = styled("div")`
    margin: 1em 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    min-height: 50px;

    .filter-btn {
        position: absolute;
        right: 10px;
        top:0
    }

    .filter-wrapper {
        display: flex;
        position: relative;
        padding-right: 200px;
        width: 100%;

        .actions {
            display: flex;
            align-items: center;
            flex-direction: row;

            button:first-of-type {
                margin-inline-end: 10px;
            }
        }
    }   
`;
