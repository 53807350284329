import dayjs from "dayjs";
import { Chip, Stack } from "@mui/material";

import { CustomerShowStyled } from "./styled";

import { ICustomer } from "@/api/types/customer";

export default function CustomerInfo({ data }: { data: ICustomer }) {
    return (
        <CustomerShowStyled>
            <div>
                <span className="label">First Name</span>
                <span>{data.firstName}</span>
            </div>
            <div>
                <span className="label">Last Name</span>
                <span>{data.lastName}</span>
            </div>
            <div>
                <span className="label">Email</span>
                <span>
                    <Stack alignItems="center" direction="row" spacing={1}>
                        <span>{data.email}</span>
                        {data.emailVerified ? <Chip color="success" label="Verified" variant="outlined" /> : null}
                    </Stack>
                </span>
            </div>
            <div>
                <span className="label">Phone Number</span>
                <span>{data.phoneNumber}</span>
            </div>
            <div>
                <span className="label">State</span>
                <span>
                    {data.state === "Enable" ? (
                        <Chip color="success" label="Enable" variant="outlined" />
                    ) : (
                        <Chip color="error" label="Blocked" variant="outlined" />
                    )}
                </span>
            </div>
            <div>
                <span className="label">Created At</span>
                <span>{dayjs(data.createdAt).format("YYYY-MM-DD HH:mm")}</span>
            </div>
            <div>
                <span className="label">Updated At</span>
                <span>{dayjs(data.updatedAt).format("YYYY-MM-DD HH:mm")}</span>
            </div>
        </CustomerShowStyled>
    );
}
