import { useMemo, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Edit } from "@mui/icons-material";
import { Chip, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { GridCheckCircleIcon } from "@mui/x-data-grid";

import { TableWrapperStyle } from "./style";
import { Data } from "./types";
import headCells from "./tableHeadCells";

import { getComparator } from "@/utils/table-utils";
import { Order } from "@/utils/table-utils";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { useUser } from "@/context/authContext";
import EnhancedTableHead from "@/components/app/EnhancedTableHeader";
import TableSkeleton from "@/components/app/TableSkeleton";
import Loading from "@/components/app/Loading/tableLoading";

export default function GenreTable({
    data: rows,
    totalItems,
    rowsPerPage,
    page,
    onChange,
    isFetching,
    isLoading,
}: {
    data: Data[],
    totalItems: number,
    page: number,
    rowsPerPage: number,
    isFetching: boolean,
    isLoading: boolean,
    // eslint-disable-next-line no-unused-vars
    onChange: (data: { page?: number, rowsPerPage?: number }) => void,
}) {
    const navigate = useNavigate();
    const user = useUser();
    const editAccess = useCheckPermissionAccess("user", "Update");

    const [order, setOrder] = useState<Order>("desc");
    const [orderBy, setOrderBy] = useState<keyof Data>("updatedAt");

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === "asc";

        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        onChange({ page: newPage, rowsPerPage });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
    };

    const handleEdit = (id: number) => navigate(ROUTE_CONSTANTS.USER_MANAGEMENT.CUSTOMERS.UPDATE.EDIT_BY_ID(id).ABSOLUTE);
    const handleDetails = (id: number) => navigate(ROUTE_CONSTANTS.USER_MANAGEMENT.CUSTOMERS.SHOW.SHOW_BY_ID(id).ABSOLUTE);

    const visibleRows = useMemo(() => [...rows].sort(getComparator(order, orderBy)), [order, orderBy, rows]);

    return (
        <TableWrapperStyle className="table-style-wrapper" sx={{ width: "100%", marginTop: "2em" }}>
            <TableContainer sx={{ maxHeight: "700px" }}>
                <Table
                    stickyHeader
                    aria-labelledby="tableTitle"
                    className="table"
                    component="div"
                    size="medium"
                    sx={{ padding: ".5em" }}
                >
                    <EnhancedTableHead<Data>
                        headCells={headCells}
                        order={order}
                        orderBy={orderBy}
                        rowCount={rows.length}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody component="div">
                        {isLoading && <TableSkeleton cells={headCells.map((item) => item.id)} />}
                        {!isLoading && !isFetching && visibleRows.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={headCells.length}>No Data To Show</TableCell>
                            </TableRow>
                        )}
                        {isFetching && <Loading />}
                        {visibleRows.map((row) => {
                            return (
                                <TableRow key={row.id} className="table-row" component="div" role="checkbox" tabIndex={-1}>
                                    <TableCell align="center" className="table-cell" component="div">
                                        <span className="table-value">{row.id}</span>
                                    </TableCell>
                                    <TableCell align="center" className="table-cell" component="div">
                                        <span className="table-value">{row.firstName || "-"}</span>
                                    </TableCell>
                                    <TableCell align="center" className="table-cell" component="div">
                                        <span className="table-value">{row.lastName || "-"}</span>
                                    </TableCell>
                                    <TableCell align="center" className="table-cell" component="div">
                                        <span className="table-value fit">
                                            <Chip
                                                color={row.hasSubscription ? "success" : "error"}
                                                icon={row.hasSubscription ? <GridCheckCircleIcon /> : <CancelIcon />}
                                                label={row.hasSubscription ? "Subscribed" : "Not Subscribed"}
                                                size="small"
                                                variant="outlined"
                                            />
                                        </span>
                                    </TableCell>
                                    <TableCell align="center" className="table-cell" component="div">
                                        <span className="table-value fit">
                                            <Chip
                                                color={row.emailVerified ? "success" : "error"}
                                                icon={row.emailVerified ? <GridCheckCircleIcon /> : <CancelIcon />}
                                                label={row.email || "-"}
                                                size="small"
                                                variant="outlined"
                                            />
                                        </span>
                                    </TableCell>
                                    <TableCell align="center" className="table-cell" component="div">
                                        <span className="table-value">{row.updatedAt}</span>
                                    </TableCell>

                                    <TableCell align="right" className="table-cell" component="div">
                                        <span className="table-value" style={{ padding: "5px", justifyContent: "flex-end" }}>
                                            <IconButton
                                                disabled={user?.id !== row.id && !editAccess}
                                                onClick={() => handleEdit(row.id)}
                                            >
                                                <Edit />
                                            </IconButton>
                                            <IconButton onClick={() => handleDetails(row.id)}>
                                                <ArrowForwardIcon />
                                            </IconButton>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={totalItems}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableWrapperStyle>
    );
}
