import {
    IGetCountriesListParams,
    IGetCountriesListResponse,
    IGetCountriesMiniListBody,
    IGetCountriesMiniListResponse,
    IGetCountryByIdResponse,
    IPutCountryByIdBody,
    IPutCountryByIdResponse,
    IAddCountryByIdBody,
    IAddCountryByIdResponse,
    IDeleteCountryByIdResponse,
} from "../types/countries";

import { API_ROUTES } from "@/constants/api-routes";
import AxiosInstance from "@/api";
import { serializeObject } from "@/utils/serializer";

export const GetCountryById = async (id: number) => {
    const result = await AxiosInstance.get<IGetCountryByIdResponse>(API_ROUTES.COUNTRY.GET_BY_ID(id));

    return result.data;
};

export const UpdateCountryById = async (id: number, body: IPutCountryByIdBody) => {
    const result = await AxiosInstance.put<IPutCountryByIdResponse>(API_ROUTES.COUNTRY.UPDATE(id), body);

    return result.data;
};

export const DeleteCountry = async (id: number) => {
    const result = await AxiosInstance.delete<IDeleteCountryByIdResponse>(API_ROUTES.COUNTRY.DELETE(id));

    return result.data;
};

export const CreateCountry = async (body: IAddCountryByIdBody) => {
    const result = await AxiosInstance.post<IAddCountryByIdResponse>(API_ROUTES.COUNTRY.CREATE, body);

    return result.data;
};

export const GetCountries = async (params: IGetCountriesListParams) => {
    const result = await AxiosInstance.get<IGetCountriesListResponse>(
        `${API_ROUTES.COUNTRY.GET}?${serializeObject(params)}`
    );

    return result.data;
};

export const GetCountriesMiniList = async (body: IGetCountriesMiniListBody) => {
    const result = await AxiosInstance.post<IGetCountriesMiniListResponse>(`/countries/mini-list`, body);

    return result.data;
};
