import { Access } from "@/constants/enums/permissions";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import Forbidden from "@/pages/Forbidden";

export default function withCheckPermissions<P>(WrappedComponent: React.ComponentType<P>, page: string, access: Access) {
    return function CheckPermissions(props: P & {}) {
        const haveAccess = useCheckPermissionAccess(page, access);

        if (haveAccess === null) return null;

        return !haveAccess ? <Forbidden /> : <WrappedComponent {...props} />;
    };
}
