import { object, string } from "yup";

export const createStaticValidationSchema = object().shape({
    en_title: string().required("English title is required"),
    ar_title: string().required("Arabic title is required"),
    slug: string().required("Slug is required"),
    en_html_content: string().required("English title is required"),
    ar_html_content: string()
        .required("Arabic content is required")
        .matches(/[\u0600-\u06FF\u0750-\u077F]/, "Is not in correct format"),
});
