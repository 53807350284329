import { API_ROUTES } from "@/constants/api-routes";
import AxiosInstance from "@/api";
import { IAxiosResponse } from "@/types/axios-response";
import { IAvatarCreatePayload, IAvatarDeleteResponse, IAvatarListResponse } from "@/api/types/avatar";
import { ITableParams, ITableResponse } from "@/types/table";

export const avatarService = {
    list(params?: ITableParams) {
        return AxiosInstance.post<IAxiosResponse<ITableResponse<IAvatarListResponse>>>(API_ROUTES.AVATARS.LIST, params);
    },
    create(payload?: IAvatarCreatePayload) {
        return AxiosInstance.post<IAxiosResponse<IAvatarCreatePayload>>(API_ROUTES.AVATARS.CREATE, payload);
    },
    delete(id: number) {
        return AxiosInstance.delete<IAxiosResponse<IAvatarDeleteResponse>>(API_ROUTES.AVATARS.DELETE(id));
    },
};
