import { styled } from "@mui/material";

export const LoadingStyled = styled("div")`
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
        max-width: 150px;
    }
`;

export const TableLoadingStyled = styled("div")`
    z-index: 10;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:after {
        z-index: 15;
        content:"";
        top: 0;
        left:0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        display: flex;
        position: absolute;
        filter: blur(2px);
    }
`;
