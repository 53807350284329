import { IFormGeneratorSchemaType } from "@/types/form-generator-schema-type";
import { FORM_INPUTS } from "@/types/form-inputs";

export type TagFormSchemaFields = "en_title" | "ar_title" | "slug" | "category";

export type ITagFormSchema = Record<TagFormSchemaFields, IFormGeneratorSchemaType>;

export const tagSchema: ITagFormSchema = {
    en_title: {
        label: "English Title",
        placeholder: "English Title",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
        },
    },
    slug: {
        label: "Slug",
        placeholder: "Slug",
        type: FORM_INPUTS.TEXT,
    },
    ar_title: {
        label: "Arabic Title",
        placeholder: "Arabic Title",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
            dir: "rtl",
        },
    },
    category: {
        label: "Category",
        placeholder: "Category",
        type: FORM_INPUTS.RADIO,
        defaultValue: 1,
        data: [
            { value: 1, label: "Education" },
            { value: 2, label: "Entertainment" },
        ],
        props: {
            required: true,
        },
    },
};
