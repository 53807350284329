import { Box, Divider, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";

import MakeSerialStyle from "./style";
import SerialCard from "./serialCard";
import AddCreateSeasonModal from "./formModal";

import { useContentList } from "@/api/hooks/useContents";
import { IContentShowResponse } from "@/api/types/content";

// TODO: make states optimize and use zustand if you wish

export default function AddSessions() {
    const { id: serialIdString } = useParams();
    const [edit, setEdit] = useState<IContentShowResponse | null>(null);
    const [open, setOpen] = useState(false);

    const serialId = Number(serialIdString);
    const { contentList, reFetch, isPending } = useContentList(serialId);

    const handleEdit = (data: IContentShowResponse) => {
        setEdit(data);
    };
    const handleClickOpen = () => setOpen(true);

    const handleClose = () => {
        if (edit) setEdit(null);
        setOpen(false);
    };

    const handleSuccess = () => {
        reFetch();
        setEdit(null);
    };

    const seasonNumbers = contentList.length > 0 ? contentList.map((season) => season.season).sort() : [0];
    const maxNumber = seasonNumbers[seasonNumbers.length - 1];

    return (
        <MakeSerialStyle>
            <Box className="page-header">
                <LoadingButton className="episode-btn" type="submit" variant="contained" onClick={handleClickOpen}>
                    Create Season
                </LoadingButton>
            </Box>
            <Divider />
            <Box className="page-content">
                {isPending && <Typography>Loading</Typography>}
                <Box className="serial-list">
                    {contentList.map((serial) => {
                        return <SerialCard key={serial.id} id={serial.id} onEdit={handleEdit} onSuccess={reFetch} />;
                    })}
                </Box>
            </Box>
            {serialId && (
                <AddCreateSeasonModal
                    data={edit}
                    maxNumber={maxNumber}
                    open={open || !!edit}
                    parentId={serialId}
                    seasonNumbers={seasonNumbers || []}
                    onClose={handleClose}
                    onSettled={() => setEdit(null)}
                    onSuccess={handleSuccess}
                />
            )}
        </MakeSerialStyle>
    );
}
