import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSnackbar } from "notistack";

import AddEpisodeForm from "../../../../forms/addEpisodes";

import { IContentCreatePayload, IContentListResponse, IContentUpdatePayload } from "@/api/types/content";
import { CONTENT_TYPE } from "@/constants/enums/content-type";
import { useCreateContent, useUpdateContent } from "@/api/hooks/useContents";
import { ICreateEpisodeForm } from "@/pages/ContentManagement/Content/forms/addEpisodes/types";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";

interface IEpisodeModal {
    open: boolean;
    refetch?: () => void;
    onClose: () => void;
    data: IContentListResponse | null;
    parentId: number;
    maxNumber: number;
    episodeNumbers: number[];
}

export default function EpisodeModal({ open, onClose, refetch, data, parentId, maxNumber, episodeNumbers }: IEpisodeModal) {
    const { mutate: createContent, isPending } = useCreateContent();
    const { mutate: updateContent, isPending: updatePending } = useUpdateContent();
    const { enqueueSnackbar } = useSnackbar();

    const createAccess = useCheckPermissionAccess("content", "Create");

    const onSubmit = (formVal: ICreateEpisodeForm) => {
        if (!createAccess) {
            enqueueSnackbar("You don't have access for creating or editing a Session");

            return;
        }

        if (
            !formVal.autoId &&
            formVal.episode &&
            formVal.episode !== data?.episode &&
            episodeNumbers.includes(formVal.episode)
        ) {
            enqueueSnackbar(`You Created a episode with the Number of ${formVal.episode} Before`, { variant: "info" });

            return;
        }

        const body: IContentCreatePayload | IContentUpdatePayload = {
            ml_name: {
                en: formVal.en_title,
                ar: formVal.ar_title,
            },
            ml_description: {
                en: formVal.en_description || "",
                ar: formVal.ar_description || "",
            },
            typeId: CONTENT_TYPE.EPISODE,
            parentId,
            status: String(formVal.sharing_state),
            episode: formVal.autoId && maxNumber ? Number(maxNumber + 1) : Number(formVal.episode),
        };

        if (formVal.mainVideo) {
            body.mainVideo = Number(formVal.mainVideo);
        }

        if (formVal.trailerVideo) {
            body.trailerVideo = Number(formVal.trailerVideo);
        }

        if (formVal.thumbnailLandscapeId) {
            body.thumbnailLandscapeId = formVal.thumbnailLandscapeId;
        }

        if (formVal.thumbnailPortraitId) {
            body.thumbnailPortraitId = formVal.thumbnailPortraitId;
        }

        if (data?.id) {
            updateContent(
                { id: Number(data?.id), body },
                {
                    onSuccess: () => {
                        if (refetch) refetch();
                        onClose();
                    },
                }
            );
        } else {
            createContent(body, {
                onSuccess: () => {
                    if (refetch) refetch();
                    onClose();
                },
            });
        }
    };

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitle> Episode Form </DialogTitle>
            <DialogContent>
                <AddEpisodeForm data={data} loading={isPending || updatePending} onSubmit={onSubmit} />
            </DialogContent>
        </Dialog>
    );
}
