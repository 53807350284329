import { styled } from "@mui/material";

export const GridCardStyled = styled("div")`
    color: #fff;
    aspect-ratio: 1;
    a {
        color: #fff;
    }
`;

export const GridCardImageStyled = styled("div")`
    display: flex;
    position: relative;
    border-radius: ${({ theme }) => theme.spacing(1)};
    height: 100%;
    width: 100%;
    z-index: 0;
    background: ${({ theme }) => (theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)")};
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: ${({ theme }) => theme.spacing(1)};
    }

    &:before {
        z-index:2;
        display: block;
        opacity: 0.3;
        width: 100%;
        height: 100%;
        content:'';
        top:0;
        left:0;
        position: absolute;
        background-color: #333;
    }

    &:hover:before {
        opacity: 0;
    }

`;

export const GridCardTitleStyled = styled("span")`
    margin-top: ${({ theme }) => theme.spacing(1)};
    color: ${({ theme }) => (theme.palette.mode === "dark" ? "#fff" : "#000")};
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
`;
export const GridCardActionsStyled = styled("div")`
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 5;
    right: 0;
    background-image: linear-gradient(to top, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
    padding: ${({ theme }) => theme.spacing(1)};
    border-radius: 0 0 ${({ theme }) => theme.spacing(1)};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1.5)};
    a {
        display: inline-flex;
    }
    svg {
        cursor: pointer;
    }
`;
