import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";

import { GetImageList, CreateImage, DeleteImage, GetImageById, GetImageListPost } from "../services/image";
import { IPagination } from "../types";

import { errorCallback } from "./error";

import { IImageListResponse, ImageFilterBody } from "@/api/types/image";

// ! this code must be removed after GET API Provided
export function useGetImageListPost(argParams?: ImageFilterBody) {
    const [data, setData] = useState<IImageListResponse>();
    const [pagination, setPagination] = useState<IPagination>();

    const { mutate, isPending } = useMutation({
        mutationFn: (params: ImageFilterBody) => GetImageListPost(params),
        onError: errorCallback,
        onSuccess: (response) => {
            setData(response);
            setPagination(response.data.pagination);
        },
    });

    const refetch = useCallback(() => {
        if (argParams) mutate(argParams);
    }, [, mutate, argParams]);

    useEffect(() => {
        refetch();
    }, [refetch]);

    return { data, pagination, refetch, isLoading: isPending };
}

export function useGetImageList(params: ImageFilterBody) {
    return useQuery({
        queryKey: ["Images", params],
        queryFn: () => GetImageList(params),
    });
}

export function useGetImageById(id: number) {
    return useQuery({
        queryKey: ["Images", id],
        enabled: !!id,
        queryFn: () => GetImageById(id),
    });
}

export function useCreateImage() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ body }: { body: FormData }) => CreateImage(body),
        onError: errorCallback,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["Images"] });
            enqueueSnackbar("The Image Updated Successfully", { variant: "success" });
        },
    });
}

export function useDeleteImage() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (id: number) => DeleteImage(id),
        onError: errorCallback,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["Images"] });
            enqueueSnackbar("The Image Deleted Successfully", { variant: "success" });
        },
    });
}
