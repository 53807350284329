import * as yup from "yup";

export default yup.object().shape({
    sharing_state: yup.number().required("required"),

    trailerVideo: yup
        .number()
        .nullable()
        .when("sharing_state", {
            is: (value: number) => Number(value) === 2,
            then: () => yup.number().nonNullable().required("You Must Select a Trailer Video"),
        }),

    thumbnailPortraitId: yup
        .number()
        .nullable()
        .when("sharing_state", {
            is: (value: number) => Number(value) === 2,
            then: () => yup.number().nonNullable().required("You Must Select Portrait Image"),
        }),

    thumbnailLandscapeId: yup
        .number()
        .nullable()
        .when("sharing_state", {
            is: (value: number) => Number(value) === 2,
            then: () => yup.number().nonNullable().required("You Must Select Landscape Image"),
        }),

    en_title: yup.string().min(2, "English title must be at most 2 characters").required("English title is required"),
    ar_title: yup.string().min(2, "Arabic title must be at most 2 characters").required("English title is required"),
    en_description: yup.string().max(600, "English description must be at least 600 characters"),
    ar_description: yup.string().max(600, "Arabic description must be at least 600 characters"),
    category: yup.number().when("sharing_state", {
        is: (value: number) => Number(value) === 2,
        then: () => yup.number().required("required"),
    }),
    genre: yup.array(yup.number().required("Genre is required")).when("sharing_state", {
        is: (value: number) => Number(value) === 2,
        then: () => yup.array(yup.number().required("Genre is required")).required("Genre is required"),
    }),
    tag: yup.array(yup.number().required("Tag is required")).when("sharing_state", {
        is: (value: number) => Number(value) === 2,
        then: () => yup.array(yup.number().required("Tag is required")).required("Tag is required"),
    }),
    age_range: yup.number().when("sharing_state", {
        is: (value: number) => Number(value) === 2,
        then: () => yup.number().required("required"),
    }),
    publisherId: yup.number().when("sharing_state", {
        is: (value: number) => Number(value) === 2,
        then: () => yup.number().required("required"),
    }),
    slug: yup.string(),
    imdb: yup.string(),
    duration: yup.string(),
    intro_duration: yup.string(),
    restrictedIn: yup.array(yup.number().defined()),
    subtitle: yup.string(),
});
