import { Outlet } from "react-router-dom";

import { ContentWrapperStyled, MainContentWrapperStyled, MainWrapperStyled } from "./styled";

import withAuth from "@/layouts/GeneralLayoutWrapper/withAuth";
import withPermissions from "@/layouts/GeneralLayoutWrapper/withPermissions";
import withUserAccount from "@/layouts/GeneralLayoutWrapper/withUserAccount";
import ModalCreator from "@/components/app/Modal/ModalCreator";
import Sidebar from "@/layouts/parts/Sidebar";
import Header from "@/layouts/parts/Header";
import { useMenuView } from "@/context/menu";
import { MENU_VIEW } from "@/constants/enums/menu";

const DashboardLayoutContent: React.FC = () => {
    const menuView = useMenuView();

    return (
        <MainWrapperStyled className={menuView === MENU_VIEW.MINIMIZE ? "minimizeMenu" : ""}>
            <Sidebar />
            <MainContentWrapperStyled>
                <Header />
                <ContentWrapperStyled>
                    <Outlet />
                </ContentWrapperStyled>
                <ModalCreator />
            </MainContentWrapperStyled>
        </MainWrapperStyled>
    );
};

const Dashboard = withAuth(withUserAccount(withPermissions(DashboardLayoutContent)));

const DashboardLayout: React.FC = () => {
    return <Dashboard />;
};

export default DashboardLayout;
