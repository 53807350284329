import React, { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { DeleteOutlineOutlined, CloudUpload } from "@mui/icons-material";
import CloudDoneIcon from "@mui/icons-material/CloudDone";

import SubtitleUploaderStyle from "./style";

import { useCreateVideoCaption, useDeleteVideoCaptions, useGetVideoCaptions } from "@/api/hooks/useVideoCaptions";
import { IVideoCaption } from "@/api/types/videoCaptions";

interface ISubtitleUploaderProps {
    id: number;
    lang: string;
    label?: string;
}

export default function SubtitleUploader(props: ISubtitleUploaderProps) {
    const { id, lang, label } = props;
    const { data } = useGetVideoCaptions(id);
    const { mutate: upload, progress } = useCreateVideoCaption();
    const { mutate: deleteCaption } = useDeleteVideoCaptions();
    const [subtitleFile, setSubtitleFile] = useState<File | null>(null);

    const disabled = !Boolean(id);

    const handleUpload = (file: File) => {
        if (file) {
            const formData = new FormData();

            formData.append("file", file);
            upload({ id, lang, body: formData });
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setSubtitleFile(e.target.files[0]);
            handleUpload(e.target.files[0]);
        }
    };

    const handleDeleteSubItem = (subTitle: IVideoCaption) => {
        const body = { id, lang: subTitle.srclang };

        deleteCaption(body, {
            onSuccess: () => {
                if (subtitleFile) setSubtitleFile(null);
            },
        });
    };

    const uploadedSubtitle = data?.data.items.filter((subs) => subs.srclang === lang)[0];

    return (
        <SubtitleUploaderStyle disabled={disabled}>
            <Typography className="text"> {label || "Select Subtitle"} </Typography>
            <Box className="contents">
                <Box className="actions">
                    <Box className="add fake-input" component="label" id="fake-input">
                        <Box className="progress" sx={{ width: `${progress}%` }} />
                        <input
                            key={`${subtitleFile?.size}${subtitleFile?.name}`}
                            accept=".vtt,.srt"
                            disabled={!id}
                            type="file"
                            onChange={handleChange}
                        />

                        {(subtitleFile || uploadedSubtitle) && (
                            <IconButton>
                                <CloudDoneIcon />
                            </IconButton>
                        )}

                        {!subtitleFile && !uploadedSubtitle && (
                            <Box className="placeholder">
                                <CloudUpload />
                                <span> Choose Subtitle ...</span>
                            </Box>
                        )}

                        {subtitleFile?.name || uploadedSubtitle?.languageName}
                        {subtitleFile && !uploadedSubtitle && (
                            <IconButton className="clear" onClick={() => setSubtitleFile(null)}>
                                <DeleteOutlineOutlined />
                            </IconButton>
                        )}
                        {uploadedSubtitle && (
                            <IconButton
                                className="clear"
                                onClick={() => uploadedSubtitle && handleDeleteSubItem(uploadedSubtitle)}
                            >
                                <DeleteOutlineOutlined />
                            </IconButton>
                        )}
                    </Box>
                </Box>
            </Box>
        </SubtitleUploaderStyle>
    );
}
