import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";

import { useAuthActions } from "@/context/authContext";
import { getAxiosError } from "@/utils/get-axios-error";
import { userService } from "@/api/services/user";
import PageLoading from "@/components/app/Loading/pageLoading";

export default function withUserAccount<P>(WrappedComponent: React.ComponentType<P>) {
    return function CheckPermissions(props: P & {}) {
        const { setUser } = useAuthActions();
        const { enqueueSnackbar } = useSnackbar();
        const [loading, setLoading] = useState<boolean>(false);
        const [error, setError] = useState<string>("");

        const GetUserAccount = useCallback(async () => {
            setLoading(true);

            try {
                const response = await userService.profile();

                setUser(response.data.data);
                setLoading(false);
            } catch (err) {
                const error = getAxiosError(err);

                setError(error.meta.message);
                enqueueSnackbar(error.meta?.message || "", { variant: "error" });
            } finally {
                setLoading(false);
            }
        }, [enqueueSnackbar, setUser, setLoading]);

        useEffect(() => {
            GetUserAccount();
        }, [GetUserAccount]);

        if (error) return <PageLoading text={error} />;

        return loading ? <PageLoading text="Loading User Profile" /> : <WrappedComponent {...props} />;
    };
}
