import { UI_SECTION } from "@/constants/enums/home-page-ui";
import { IFormGeneratorSchemaType } from "@/types/form-generator-schema-type";
import { FORM_INPUTS } from "@/types/form-inputs";

export type HomeUiSectionFormSchemaFields = "tag" | "type" | "ml_title_en" | "ml_title_ar" | "itemCount";

export type IHomeUiSectionFormSchema = Record<HomeUiSectionFormSchemaFields, IFormGeneratorSchemaType>;

export const homeUiSectionSchema: IHomeUiSectionFormSchema = {
    type: {
        label: "Module",
        placeholder: "type",
        type: FORM_INPUTS.SELECT,
        data: Object.entries(UI_SECTION).map(([, label]) => ({ label, value: label })),
        props: {
            required: true,
        },
    },

    tag: {
        label: "Tag",
        placeholder: "tag",
        type: FORM_INPUTS.SELECT,
    },

    ml_title_en: {
        label: "Title (English)",
        placeholder: "Enter title in English",
        type: FORM_INPUTS.TEXT,
    },

    ml_title_ar: {
        label: "Title (Arabic)",
        placeholder: "Enter title in Arabic",
        type: FORM_INPUTS.TEXT,
        props: {
            dir: "rtl",
        },
    },

    itemCount: {
        label: "Item Count",
        placeholder: "Enter number of items",
        type: FORM_INPUTS.TEXT,
        defaultValue: 8,
        props: {
            required: true,
            type: "number",
        },
    },
};
