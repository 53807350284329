import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

import { IUpdateUserBody, ICreateUserBody, IUserFilterParams, IActivateUserBody } from "../types/user";

import { errorCallback } from "@/api/hooks/error";
import { GetUsers, DeleteUser, UpdateUser, UpdateUserActivation, CreateUser } from "@/api/services/user";

export function useUserList(params: IUserFilterParams) {
    return useQuery({
        queryKey: ["Users", params],
        queryFn: () => GetUsers(params),
    });
}

export function useDeleteUser() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (id: number) => DeleteUser(id),
        onError: errorCallback,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["Users"] });
            enqueueSnackbar("The User Deleted Successfully", { variant: "success" });
        },
    });
}

export function useUpdateUser() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ id, body }: { id: number, lang?: string, body: IUpdateUserBody }) => UpdateUser(id, body),
        onError: errorCallback,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["Users"] });
            enqueueSnackbar("The User Updated Successfully", { variant: "success" });
        },
    });
}

export function useUpdateUserActivation() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ id, body }: { id: number, lang?: string, body: IActivateUserBody }) => UpdateUserActivation(id, body),
        onError: errorCallback,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["Users"] });
            enqueueSnackbar("The User Updated Successfully", { variant: "success" });
        },
    });
}

export function useCreateUser() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ body }: { lang?: string, body: ICreateUserBody }) => CreateUser(body),
        onError: errorCallback,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["Users"] });
            enqueueSnackbar("The User Updated Successfully", { variant: "success" });
        },
    });
}
