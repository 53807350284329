import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, SelectChangeEvent, Grid } from "@mui/material";
import { FilterList, FilterListOff } from "@mui/icons-material";
import { useState } from "react";

import FiltersStyle from "./style";

import { GenreFilterBody } from "@/api/types/genre";

export const defaultValues: Omit<GenreFilterBody, "skip" | "take"> = { search: "", category: null };

// eslint-disable-next-line no-unused-vars
export default function GenreFilters({ onApply }: { onApply: (data: typeof defaultValues) => void }) {
    const [fields, setFields] = useState(defaultValues);
    const [filterActivated, setFilterActivated] = useState<boolean>(false);

    const toggleFilters = () => setFilterActivated((prev) => !prev);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
        const name = event.target.name;
        const value = event.target.value;

        setFields((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleReset = () => {
        setFields(defaultValues);
        if (onApply) onApply(defaultValues);
    };

    const handleApply = () => onApply && onApply(fields);

    return (
        <FiltersStyle>
            <Box className="filter-wrapper">
                {filterActivated && (
                    <Grid container spacing={1}>
                        <Grid item lg={3} md={4} xs={12}>
                            <TextField
                                fullWidth
                                className="input field "
                                label="Search"
                                name="search"
                                placeholder="Enter some text"
                                size="small"
                                value={fields.search}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item lg={3} md={4} xs={12}>
                            <FormControl fullWidth className="select field" size="small">
                                <InputLabel id="select-label">Category</InputLabel>
                                <Select
                                    id="select"
                                    label="Category"
                                    labelId="select-label"
                                    name="category"
                                    value={String(fields.category)}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="education">Education</MenuItem>
                                    <MenuItem value="entertainment">Entertainment</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} xs={12}>
                            <Box className="actions">
                                <Button className="action-btn" variant="contained" onClick={handleApply}>
                                    Apply
                                </Button>
                                <Button className="action-btn" variant="contained" onClick={handleReset}>
                                    reset
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Box>
            <Button
                className="filter-btn"
                startIcon={filterActivated ? <FilterListOff /> : <FilterList />}
                variant="contained"
                onClick={toggleFilters}
            >
                Filters
            </Button>
        </FiltersStyle>
    );
}
