import { CircularProgress, Typography } from "@mui/material";

import { LoadingStyled } from "./styled";

const InitLoading = ({ height = "50vh", text }: { height?: string, text?: string }) => {
    return (
        <LoadingStyled style={{ height, display: "flex" }}>
            <center>
                <CircularProgress />
                <Typography>{text}</Typography>
            </center>
        </LoadingStyled>
    );
};

export default InitLoading;
