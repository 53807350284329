import { useQuery } from "@tanstack/react-query"; // useQueryClient

import { GetPayments } from "@/api/services/payment";
import { IPaymentHistoryParams } from "@/api/types/payments";

export default function usePaymentHistory(params?: Partial<IPaymentHistoryParams>) {
    const queryKey = params ? ["Customer", params] : ["Customer"];

    return useQuery({
        queryKey,
        queryFn: () => GetPayments(params),
    });
}
