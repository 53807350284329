import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";

import { authService } from "@/api/services/auth";
import { useAuthActions } from "@/context/authContext";
import { getAxiosError } from "@/utils/get-axios-error";
import PageLoading from "@/components/app/Loading/pageLoading";

export default function withProtectedRoutes<P>(WrappedComponent: React.ComponentType<P>) {
    return function CheckPermissions(props: P & {}) {
        const { setUserPermissions } = useAuthActions();
        const { enqueueSnackbar } = useSnackbar();
        const [loading, setLoading] = useState<boolean>(false);

        const GetPermissions = useCallback(async () => {
            setLoading(true);

            try {
                const response = await authService.permissions();

                setUserPermissions(response.data.data);
                setLoading(false);
            } catch (err) {
                const error = getAxiosError(err);

                setLoading(false);
                enqueueSnackbar(error.meta?.message || "", { variant: "error" });
            }
        }, [enqueueSnackbar, setUserPermissions]);

        useEffect(() => {
            GetPermissions();
        }, [GetPermissions]);

        return loading ? (
            <PageLoading height="100vh" text="Loading Permissions Please wait" />
        ) : (
            <WrappedComponent {...props} />
        );
    };
}
