import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";

import { IContentCreateFormType } from "../../../forms/addMovieForm/type";
import AddSerialForm from "../../../forms/addSeriesForm";

import { useCreateContent, useUpdateContent } from "@/api/hooks/useContents";
import { IContentCreatePayload, IContentUpdatePayload } from "@/api/types/content";
import { CONTENT_TYPE } from "@/constants/enums/content-type";
import ROUTE_CONSTANTS from "@/constants/route-constants";

export default function AddSerials({ nextStep }: { nextStep: () => void }) {
    const { id } = useParams();
    const { mutate: createContent } = useCreateContent();
    const { mutate: updateContent } = useUpdateContent();
    const navigate = useNavigate();

    const handleSubmit = (formVal: IContentCreateFormType) => {
        const body: IContentCreatePayload | IContentUpdatePayload = {
            ageRangeId: Number(formVal.age_range),
            genres: formVal.genre,
            imdb: formVal.imdb,
            introLength: Number(formVal.intro_duration),
            isEntertainment: formVal.category === 2,
            isEducation: formVal.category === 1,
            length: Number(formVal.duration),
            ml_name: {
                en: formVal.en_title,
                ar: formVal.ar_title,
            },
            ml_description: {
                en: formVal.en_description,
                ar: formVal.ar_description,
            },
            slug: formVal.slug,
            status: String(formVal.sharing_state),
            tags: formVal.tag,
            restrictedIn: formVal.restrictedIn,
            publisherId: formVal.publisherId,
            typeId: CONTENT_TYPE.SERIES,
        };

        if (formVal.mainVideo) {
            body.mainVideo = Number(formVal.mainVideo);
        }

        if (formVal.trailerVideo) {
            body.trailerVideo = formVal.trailerVideo;
        }

        if (formVal.thumbnailLandscapeId) {
            body.thumbnailLandscapeId = formVal.thumbnailLandscapeId;
        }

        if (formVal.thumbnailPortraitId) {
            body.thumbnailPortraitId = formVal.thumbnailPortraitId;
        }

        if (id) {
            updateContent(
                { id: Number(id), body },
                {
                    onSuccess: (response) => {
                        navigate(
                            ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.UPDATE_SERIES.EDIT_BY_ID(response.data.data.id)
                                .ABSOLUTE
                        );
                        nextStep();
                    },
                }
            );
        } else {
            createContent(body, {
                onSuccess: (response) => {
                    navigate(
                        ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.UPDATE_SERIES.EDIT_BY_ID(response.data.data.id).ABSOLUTE
                    );
                    nextStep();
                },
            });
        }
    };

    return (
        <Box className="wrapper" sx={{ maxWidth: "920px", margin: "0 auto", paddingBottom: "2em" }}>
            <AddSerialForm onSubmit={handleSubmit} />
        </Box>
    );
}
