import { styled } from "@mui/material";

export default styled("div")`
    max-width: 920px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .plans-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 1em;
        .title {
            font-weight: bold;
            font-size: 1.5em;
        }
    }

`;

export const TableOfContentStyles = styled("div")(
    ({ theme }) => `
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 2em;
    border: 1px solid #ccc;
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
    border-radius: ${theme.spacing(2)};
    > div {
        display: flex;
        align-items: center;
        line-height: 40px;
        border-bottom: 1px solid #ccc;
        padding: ${theme.spacing(1)} 0;

        &:last-child {
            border-bottom: unset
        }

      

        span.label {
            text-align: right;
            margin: 0;
            font-weight: 600;
            padding-right: ${theme.spacing(2)};
            width: 33.3333%;
            flex: 0 0 33.3333%;
            justify-content: flex-end;
        }

        > span {
            padding-left: ${theme.spacing(2)};
            border-left: 1px solid #ccc;
            width: 66.6666%;
            flex: 0 0 66.6666%;
            align-items: center;
            justify-content:center;

            &:first-of-type {
                border-left: none;
            }

            img {
                display: inline-flex;
                width: 30px;
                height: 30px;
            }
        }
    }
`
);
