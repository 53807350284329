import { styled } from "@mui/material";

export default styled("div")`

    .page-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title-box {
            display: flex;
            flex-direction: column;

            h2 {
                font-size: 22px;
                font-weight: bold;
                margin-bottom: 0.5em;
            }

            p {
                font-size: 14px;
                color: #dedede
            }
        }
    }

`;

export const UserHeaderStyle = styled("div")`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .page-title {
        font-size: 25px;
        font-weight: bold;
    }
`;

export const TableWrapperStyle = styled("div")`

    .table-value {
        width: 100%;
        height: 100%;
        max-height: 600px;
        display: flex;
        padding: 15px;
        box-sizing: border-box;
        max-height: 50px;
        overflow: hidden;

        &.fit {
            padding: 13px !important;
        }

        &:hover {
            background-color: transparent;
        }
        
    }

    .table-row {

        &:hover {
            background-color: transparent;
        }

        .table-cell {
            padding:0;
            border:none;
            margin: 2px 0;
            box-sizing: border-box;

            &:first-of-type {
                .table-value {
                    border-top-left-radius: 12px;
                    border-bottom-left-radius: 12px;
                }
            }

            &:last-of-type {
                .table-value { 
                    border-top-right-radius: 12px;
                    border-bottom-right-radius: 12px;
                }
            }
        }

        .table-value {
            margin: 6px 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            box-sizing: border-box;
            background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#f5f5f5" : theme.palette.grey[800])};
        }

        /* &:nth-of-type(even) {
            .table-value {
                background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#f5f5f5" : theme.palette.grey[800])};
            }
        } */
    }
`;
