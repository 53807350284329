import { Controller, FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";

import { ICreateSeasonForm } from "./type";
import Validation from "./validation";

import FormTextarea from "@/components/kit/FormGenerator/FormTextarea";
import FormText from "@/components/kit/FormGenerator/FormText";
import FormTextRtl from "@/components/kit/FormGenerator/FormTextRtl";
import FormTextareaRtl from "@/components/kit/FormGenerator/FormTextareaRtl";
import FormCheckbox from "@/components/kit/FormGenerator/FormCheckbox";
import FormRadio from "@/components/kit/FormGenerator/FormRadio";
import { IContentShowResponse } from "@/api/types/content";

const defaultValues = {
    en_title: "",
    ar_title: "",
    en_description: "",
    ar_description: "",
    season: 1,
    autoId: false,
    sharing_state: 1,
};

export default function AddCreateSeasons({
    onSubmit,
    data,
}: {
    data: IContentShowResponse | null,
    // eslint-disable-next-line no-unused-vars
    onSubmit: (formData: ICreateSeasonForm) => void,
}) {
    const content = data;

    const {
        control,
        reset,
        watch,
        handleSubmit,
        trigger,
        formState: { errors, isValid },
    } = useForm<ICreateSeasonForm>({
        defaultValues,
        mode: "all",
        resolver: yupResolver(Validation),
    });

    // this code is very important - it's like refresh button for react-hook-form, make it update.
    useEffect(() => {
        if (isValid) void trigger();
    }, [trigger, isValid]);

    useEffect(() => {
        if (!data) reset(defaultValues);
    }, [reset, data]);

    // update form data in Edit mode
    useEffect(() => {
        // make sure genre & tags & ageRange are fetched before
        if (content) {
            const { ml_description, ml_name, season, status } = content;

            reset({
                season,
                sharing_state: status === "Draft" ? 1 : 2,
                ar_title: ml_name.ar,
                en_title: ml_name.en,
                ar_description: ml_description.ar,
                en_description: ml_description.en,
            });
        }
    }, [content, reset]);

    const autoId = watch("autoId");
    const required = autoId;

    return (
        <Box className="add-series-form">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Typography sx={{ fontSize: "12px" }}>
                    If you want the Season numbers to be consecutive, you must enter the Season number yourself.
                </Typography>
                <Controller
                    key="autoId"
                    control={control}
                    name="autoId"
                    render={({ field }) => (
                        <FormCheckbox
                            className="checkbox"
                            errorMessage={errors["autoId"] as FieldError}
                            field={{ ...field }}
                            label="Add Season number automatically (Do not care about the order)"
                            placeholder="Add Season number automatically (Do not care about the order)"
                        />
                    )}
                />

                <Controller
                    key="season"
                    control={control}
                    disabled={autoId}
                    name="season"
                    render={({ field }) => (
                        <FormText
                            className="textarea"
                            errorMessage={errors["season"] as FieldError}
                            field={{ ...field, value: field.value ? parseInt(`${field.value}`, 10) : field.value }}
                            label="Enter Season No"
                            placeholder="Enter Season No"
                            props={{ type: "number", required }}
                        />
                    )}
                />
                <Controller
                    key="en_title"
                    control={control}
                    name="en_title"
                    render={({ field }) => (
                        <FormText
                            errorMessage={errors["en_title"] as FieldError}
                            field={{ ...field }}
                            label="English Content Name"
                            placeholder="Enter English Text"
                            props={{ required: true }}
                        />
                    )}
                />
                <Controller
                    key="ar_title"
                    control={control}
                    name="ar_title"
                    render={({ field }) => (
                        <FormTextRtl
                            errorMessage={errors["ar_title"] as FieldError}
                            field={{ ...field }}
                            label="Arabic Content Name"
                            placeholder="Enter Arabic Text"
                            props={{ required: true }}
                        />
                    )}
                />
                <Controller
                    key="en_description"
                    control={control}
                    name="en_description"
                    render={({ field }) => (
                        <FormTextarea
                            className="textarea"
                            errorMessage={errors["en_description"] as FieldError}
                            field={{ ...field }}
                            label="English Description"
                            placeholder="Enter English Text"
                        />
                    )}
                />
                <Controller
                    key="ar_description"
                    control={control}
                    name="ar_description"
                    render={({ field }) => (
                        <FormTextareaRtl
                            className="textarea"
                            errorMessage={errors["ar_description"] as FieldError}
                            field={{ ...field }}
                            label="Arabic Description"
                            placeholder="Enter Arabic Text"
                        />
                    )}
                />
                <Controller
                    key="sharing_state"
                    control={control}
                    name="sharing_state"
                    render={({ field }) => (
                        <FormRadio
                            className="sharing_state"
                            data={[
                                { value: 1, label: "Draft" },
                                { value: 2, label: "Publish" },
                            ]}
                            errorMessage={errors["sharing_state"] as FieldError}
                            field={{ ...field }}
                            label="Sharing State"
                            placeholder="Select Option"
                            props={{ required: true }}
                        />
                    )}
                />
                <LoadingButton className="loading-btn" type="submit" variant="contained">
                    {data ? "Update" : "Create"} Content
                </LoadingButton>
            </form>
        </Box>
    );
}
