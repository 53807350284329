import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DataGrid, GridColDef, GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import { AxiosResponse } from "axios";
import { Grid, InputAdornment, OutlinedInput, Stack } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";

import { IAxiosResponse } from "@/types/axios-response";
import { ITablePaginate, ITableParams, ITableResponse } from "@/types/table";
import InitLoading from "@/components/app/Loading/InitLoading";
import { useDebounce } from "@/hooks/useDebounce";
import { useIsMount } from "@/hooks/useIsMount";
import { ILazyParams } from "@/types/filters";
import { TABLE_ORDER } from "@/constants/enums/column-order";

export interface IProps<T> {
    // eslint-disable-next-line no-unused-vars
    service: (queryParams?: ITableParams) => Promise<AxiosResponse<IAxiosResponse<ITableResponse<T>>>>;
    // eslint-disable-next-line no-unused-vars
    suffixCols?: (tableData: T[], setTableData: Dispatch<SetStateAction<T[]>>) => GridColDef[];
    columnsSchema: GridColDef[];
    filterPermission?: string;
    loading?: boolean;
    showSearch?: boolean;
    setLazyParams?: Dispatch<SetStateAction<ILazyParams>>;
    lazyParams?: ILazyParams;
}

interface SortObject {
    [key: string]: number; // allows any string key with a number value
}

function Table<T>({ service, suffixCols, columnsSchema, loading, showSearch = true, setLazyParams, lazyParams }: IProps<T>) {
    const [searchLoading, setSearchLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [queryOptions, setQueryOptions] = useState<ITableParams | undefined>();
    const isMount = useIsMount();
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: lazyParams?.page || 0,
        pageSize: lazyParams?.rows || 10,
    });
    const [pageInfo, setPageInfo] = useState<ITablePaginate>();
    const [initLoading, setInitLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);
    const [tableData, setTableData] = useState<T[]>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);

    const rowCountRef = useRef(pageInfo?.totalItems || 0);

    const rowCount = useMemo(() => {
        if (pageInfo?.totalItems !== undefined) {
            rowCountRef.current = pageInfo.totalItems;
        }

        return rowCountRef.current;
    }, [pageInfo?.totalItems]);

    const fetchData = async () => {
        setTableLoading(true);
        const tableData = typeof service === "function" ? await service(queryOptions) : service;

        if (tableData?.data) {
            const {
                data: {
                    data: { items, pagination },
                },
            } = tableData;

            setTableData(items);
            setInitLoading(false);
            setPageInfo(pagination);
        }
        setTableLoading(false);
        setSearchLoading(false);
    };

    const handleSortModelChange = useCallback(
        (model: GridSortModel) => {
            setSortModel(model);
            const sort: SortObject = {};

            if (model.length) {
                const { field, sort: order } = model[0];

                sort[field] = order === "asc" ? TABLE_ORDER.ASC : TABLE_ORDER.DSC;
            }
            if (setLazyParams) {
                setLazyParams((prev: ILazyParams) => ({ ...prev, sort: sort }));
            }
        },
        [fetchData]
    );

    const handlePaginationModelChange = useCallback((model: GridPaginationModel) => {
        setQueryOptions((prev) => ({ ...prev, take: model.pageSize, skip: model.page * model.pageSize }));
        setPaginationModel(model);
        if (setLazyParams) {
            setLazyParams((prev: ILazyParams) => ({ ...prev, page: model.page, rows: model.pageSize }));
        }
    }, []);

    const fetchDataDebounce = useDebounce(() => {
        setQueryOptions((prev) => ({ ...prev, search }));
    });

    const onSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
        setSearchLoading(true);
    };

    const clearSearch = () => {
        setSearch("");
        setSearchLoading(true);
    };

    useEffect(() => {
        if (!isMount) {
            fetchDataDebounce();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryOptions, service]);

    if (initLoading) return <InitLoading />;

    if (!initLoading && !tableData) return <h5>Oops! Something Went Wrong!</h5>;

    return (
        <>
            {showSearch && (
                <Stack marginBottom={3}>
                    <Grid item xs={4}>
                        <OutlinedInput
                            endAdornment={
                                <InputAdornment position="end">
                                    {searchLoading ? (
                                        <CircularProgress size={24} />
                                    ) : search.length ? (
                                        <CloseIcon sx={{ cursor: "pointer" }} onClick={clearSearch} />
                                    ) : null}
                                </InputAdornment>
                            }
                            placeholder="Search ..."
                            size="small"
                            value={search}
                            onChange={onSearch}
                        />
                    </Grid>
                </Stack>
            )}
            <DataGrid
                disableColumnMenu
                columns={suffixCols ? [...columnsSchema, ...suffixCols(tableData, setTableData)] : columnsSchema}
                loading={loading || tableLoading}
                pageSizeOptions={[10, 20, 50, 100]}
                paginationMode="server"
                paginationModel={paginationModel}
                rowCount={rowCount}
                rows={tableData}
                sortingMode="server"
                sx={{
                    height: "calc(100% - 64px)",
                    "--DataGrid-overlayHeight": "100%",
                    borderRadius: "unset",
                    border: "unset",
                    "& .MuiDataGrid-topContainer": {
                        "&:after": {
                            display: "none",
                        },
                    },
                    "& .MuiDataGrid-columnHeaderTitleContainers": {
                        fontWeight: 800,
                    },
                    "& .MuiDataGrid-row": {
                        border: "unset",
                        marginBottom: "12px",
                        background: (theme) => (theme.palette.mode === "dark" ? "#353535" : "#f5f5f5"),
                        borderRadius: "12px",
                        width: "100%",
                    },
                    "& .MuiDataGrid-cell": {
                        border: "unset",
                    },
                    "& .MuiDataGrid-filler": {
                        div: {
                            border: "unset",
                        },
                    },
                    "& .MuiDataGrid-columnHeader": {
                        "& .MuiDataGrid-iconButtonContainer": {
                            visibility: "visible", // Always visible
                        },
                        "& .MuiDataGrid-sortIcon": {
                            opacity: "inherit !important", // Always visible
                            padding: "2px",
                            borderRadius: "50%",
                            backgroundColor: sortModel.length && "#0F62F3 !important",
                            color: sortModel.length && "#f5f5f5 !important",
                            "&:hover": {
                                backgroundColor: "#0F62F9",
                                color: "#f5f5f5",
                            },
                        },
                    },
                }}
                onPaginationModelChange={handlePaginationModelChange}
                onSortModelChange={handleSortModelChange}
            />
        </>
    );
}

export default Table;
