import { Controller, FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";

import { IVideoUpdateFormType } from "./type";
import { updateVideoValidationSchema } from "./validation";

import FormText from "@/components/kit/FormGenerator/FormText";
import FormTextArea from "@/components/kit/FormGenerator/FormTextarea";
import FormSelectImage from "@/components/kit/FormGenerator/FormSelectImage";
import { videoService } from "@/api/services/video";
import { getAxiosError } from "@/utils/get-axios-error";

const defaultValues: IVideoUpdateFormType = {
    title: "",
    thumbnailId: 0,
    description: "",
};

export default function AddVideoForm({
    onSubmit,
    submitLoading,
}: {
    // eslint-disable-next-line no-unused-vars
    onSubmit: (val: IVideoUpdateFormType) => void,
    submitLoading?: boolean,
}) {
    const { id } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [previewImage, setPreview] = useState("");

    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
        reset,
        trigger,
    } = useForm<IVideoUpdateFormType>({
        defaultValues,
        mode: "all",
        resolver: yupResolver(updateVideoValidationSchema),
    });

    // this code is very important - it's like refresh button for react-hook-form, make it update.
    useEffect(() => {
        void trigger();
    }, [trigger, isValid]);

    const getEditData = useCallback(
        async (id: number) => {
            try {
                setLoading(true);
                const response = await videoService.show(id);

                const { thumbnail: Thumbnail, description, title } = response.data.data;

                if (Thumbnail && Thumbnail.url) setPreview(Thumbnail.url);
                reset({ title, description, thumbnailId: Thumbnail?.id });
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.meta.message || "Server Error";

                enqueueSnackbar(message, { variant: "error" });
            } finally {
                setLoading(false);
            }
        },
        [reset, enqueueSnackbar]
    );

    useEffect(() => {
        if (id) getEditData(Number(id));
    }, [id, getEditData]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                    <Controller
                        key="thumbnailId"
                        control={control}
                        name="thumbnailId"
                        render={({ field }) => (
                            <FormSelectImage
                                className="field"
                                errorMessage={errors["thumbnailId"] as FieldError}
                                field={{ ...field }}
                                label="Thumbnail"
                                placeholder="(Max Size: 3Mb)"
                                props={{
                                    required: true,
                                    previewImage,
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Controller
                        key="title"
                        control={control}
                        name="title"
                        render={({ field }) => (
                            <FormText
                                className="field"
                                errorMessage={errors["title"] as FieldError}
                                field={{ ...field }}
                                label="Title"
                                placeholder="Enter Text "
                                props={{
                                    required: true,
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Controller
                        key="description"
                        control={control}
                        name="description"
                        render={({ field }) => (
                            <FormTextArea
                                className="field textarea"
                                errorMessage={errors["description"] as FieldError}
                                field={{ ...field }}
                                label="Description"
                                placeholder="Enter Description Text"
                                props={{
                                    required: true,
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Stack direction={"row"} paddingBottom={10} spacing={2}>
                        <LoadingButton
                            disabled={!isValid || loading || submitLoading}
                            loading={loading}
                            type="submit"
                            variant="contained"
                        >
                            Update Video
                        </LoadingButton>
                        <Button type="button" onClick={() => navigate(-1)}>
                            Cancel
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </form>
    );
}
