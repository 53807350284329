import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useState } from "react";

import AddEpisodeStyle from "./style";
import EachEpisode from "./eachEpisode";

import { useContentList } from "@/api/hooks/useContents";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            aria-labelledby={`simple-tab-${index}`}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            role="tabpanel"
            {...other}
        >
            {value === index && <Box className="tab-panel">{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function AddEpisodes() {
    const { id: parentId } = useParams();
    const [value, setValue] = useState(0);
    const { contentList: sessionList, isPending } = useContentList(Number(parentId));

    const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue);

    if (isPending) return <p>Loading</p>;

    if (sessionList.length === 0) return <Typography>You did not define any Sessions yet</Typography>;

    return (
        <AddEpisodeStyle>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    aria-label="basic tabs example"
                    scrollButtons="auto"
                    value={value}
                    variant="scrollable"
                    onChange={handleChange}
                >
                    {sessionList.map((item, index) => {
                        return <Tab key={item.id} label={item.ml_name.en} {...a11yProps(index)} />;
                    })}
                </Tabs>
            </Box>
            {sessionList.map((item, index) => {
                return (
                    <CustomTabPanel key={item.id} index={index} value={value}>
                        <EachEpisode sessionId={item.id} />
                    </CustomTabPanel>
                );
            })}
        </AddEpisodeStyle>
    );
}
