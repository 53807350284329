import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";
import { FormHelperText } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useRef, useState } from "react";

import {
    DeleteIconStyled,
    FormImageInputStyled,
    FormImageStyled,
    FormImageUploadStyled,
    FormImageWrapperStyled,
} from "./styled";

import { ImageFieldSchema } from "@/types/form-generator-schema-type";

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label?: string;
    placeholder: string;
    className?: string;
    errorMessage: FieldError;
    props?: ImageFieldSchema["props"];
}
const FormImage = ({ field, label, placeholder, className, errorMessage, props = {} }: IProps) => {
    const { previewImage, previewSize, previewName, ...inputProps } = props || {};
    const inputFileRef = useRef<HTMLInputElement>(null);

    const [imageSource, setImageSource] = useState(previewImage || "");
    const [fileName, setFileName] = useState<string>(previewName || "");
    const [fileSize, setFileSize] = useState<number | undefined>(previewSize || undefined);

    const isImageInput = props?.accept && props.accept === "image/*";

    // ! Change handleOnChange in order to handle cancel image select for trigger error in react-form-hook
    // const handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    //     if (event.target.files) {
    //         setFileName(event.target.files[0].name);
    //         setFileSize(event.target.files[0].size);
    //         field.onChange(event.target.files[0]);
    //         isImageInput && setImageSource(URL.createObjectURL(event.target.files[0]));
    //     }
    // };

    const handleRemove = () => {
        setFileName("");
        setFileSize(undefined);
        field.onChange(undefined);
        isImageInput && setImageSource("");
        if (inputFileRef.current) {
            inputFileRef.current.value = "";
        }
    };

    const handleFocusBack = () => {
        field.onBlur();
        window.removeEventListener("focus", handleFocusBack);
    };

    const fileInputClicked = (event: React.ChangeEvent<HTMLInputElement>) => {
        let file = event?.target?.files && event.target.files[0];

        if (file) {
            setFileName(file.name);
            setFileSize(file.size);
            field.onChange(file);
            isImageInput && setImageSource(URL.createObjectURL(file));
        }
        window.removeEventListener("focus", handleFocusBack);
    };

    const handleClick = () => window.addEventListener("focus", handleFocusBack);

    return (
        <>
            <FormImageStyled className={className}>
                <label>
                    {label} {props?.required ? <span style={{ color: "red", fontSize: "20px" }}>*</span> : ""}
                </label>
                <FormImageWrapperStyled>
                    {isImageInput && imageSource?.length ? <img alt={label} src={imageSource} /> : null}
                    {field.value ? <DeleteIconStyled onClick={handleRemove} /> : null}
                    {!fileName?.length ? (
                        <FormImageUploadStyled>
                            <CloudUploadIcon />
                            <span>Choose File ...</span>
                            {placeholder ? <small>{placeholder}</small> : null}
                        </FormImageUploadStyled>
                    ) : null}
                    <FormImageInputStyled
                        ref={inputFileRef}
                        type="file"
                        onChange={fileInputClicked}
                        onClick={handleClick}
                        {...inputProps}
                    />
                </FormImageWrapperStyled>
                {fileName || fileSize ? (
                    <span>
                        {fileName?.length ? fileName : null}
                        {fileSize ? <small>({Math.ceil(fileSize / 1024)} Kb)</small> : null}
                    </span>
                ) : null}
            </FormImageStyled>
            {errorMessage?.message ? (
                <FormHelperText error sx={{ paddingLeft: "20px" }}>
                    {errorMessage?.message}
                </FormHelperText>
            ) : null}
        </>
    );
};

export default FormImage;
