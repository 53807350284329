import withCheckPermissions from "@/layouts/GeneralLayoutWrapper/withCheckPermissions";
import StaticList from "@/pages/Settings/Static";
import StaticForm from "@/pages/Settings/Static/AddUpdate";
import ShowCountries from "@/pages/Settings/Countries/show";
import CountryDetail from "@/pages/Settings/Countries/detail";
import Plans from "@/pages/Settings/Plans";
import PlansCreate from "@/pages/Settings/Plans/create";
import PlansEdit from "@/pages/Settings/Plans/edit";
import PlansDetail from "@/pages/Settings/Plans/details";

export const ProtectedCountriesList = withCheckPermissions(ShowCountries, "country", "List");
export const ProtectedCountriesDetails = withCheckPermissions(CountryDetail, "country", "List");

export const ProtectedStaticList = withCheckPermissions(StaticList, "static", "List");
export const ProtectedStaticFormUpdate = withCheckPermissions(StaticForm, "static", "Update");
export const ProtectedStaticFormCreate = withCheckPermissions(StaticForm, "static", "Create");

export const ProtectedPlanList = withCheckPermissions(Plans, "plan", "List");
export const ProtectedPlanFormUpdate = withCheckPermissions(PlansEdit, "plan", "Update");
export const ProtectedPlanFormCreate = withCheckPermissions(PlansCreate, "plan", "Create");
export const ProtectedPlanFormDetails = withCheckPermissions(PlansDetail, "plan", "List");
