import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

import { errorCallback } from "./error";

import { IPublisherListReqParams } from "@/api/types/publisher";
import {
    GetPublishersList,
    GetPublisherById,
    UpdatePublisherById,
    DeletePublisherById,
    CreatePublisherById,
} from "@/api/services/publisher";

export function usePublisherList(params: IPublisherListReqParams, lang?: string) {
    return useQuery({
        queryKey: ["Publishers", "Publisher", params],
        queryFn: () => GetPublishersList(lang || "en", params),
    });
}

export function usePublisherById(id: number, lang?: string) {
    return useQuery({
        enabled: !!id,
        queryKey: ["Publisher", id],
        queryFn: () => GetPublisherById(Number(id), lang),
    });
}

export function useUpdatePublisher() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ id, body, lang }: { id: number, lang: string, body: { title: string } }) =>
            UpdatePublisherById(id, lang, body),
        onError: errorCallback,
        onSuccess: (data, { id }) => {
            enqueueSnackbar(`${data.data.title}`, { variant: "success" });
            queryClient.invalidateQueries({ queryKey: ["Publisher", id] });
        },
    });
}

export function useDeletePublisher() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ id, lang }: { id: number, lang?: string }) => DeletePublisherById(id, lang),
        onError: errorCallback,
        onSuccess: (data) => {
            enqueueSnackbar(`${data.data.result}`, { variant: "success" });
            queryClient.invalidateQueries({ queryKey: ["Publishers"] });
        },
    });
}

export function useCreatePublisher() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ body, lang }: { lang: string, body: { title: string } }) => CreatePublisherById(lang, body),
        onError: errorCallback,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["Publisher"] });
            enqueueSnackbar(`Publisher Create Successfully`, { variant: "success" });
        },
    });
}
