import { FormEventHandler, ReactNode } from "react";
import { Control, Controller } from "react-hook-form";
import { FormControl, Grid } from "@mui/material";

import FormPhoneNumber from "./FormPhoneNumber";
import FormPassword from "./FormPassword";
import { FormGeneratorStyled } from "./styled";
import FieldSet from "./FieldSet";
import FormRadio from "./FormRadio";
import FormText from "./FormText";
import FormSelect from "./FormSelect";
import FormCheckbox from "./FormCheckbox";
import FormTextarea from "./FormTextarea";
import FormMedia from "./FormImage";
import FormMultiSelect from "./FormMultiSelect";
import FormVideo from "./FormVideo";
import FormSelectVideo from "./FormSelectVideo";
import FormSelectImage from "./FormSelectImage";
import FormRichTextEditor from "./FormTextEditor";
import FormCountryCode from "./FormCountryCode";
import FormPhone from "./FormPhone";

import InitLoading from "@/components/app/Loading/InitLoading";
import { IFormGeneratorSchemaType } from "@/types/form-generator-schema-type";
import { FORM_INPUTS } from "@/types/form-inputs";

interface IProps {
    loading?: boolean;
    control: Control<any, any>;
    errors: any;
    schema: Record<any, Omit<IFormGeneratorSchemaType, "props">>;
    onSubmit?: FormEventHandler<HTMLFormElement>;
    footer?: ReactNode;
}

const FormGenerator = ({ onSubmit, loading, control, schema, errors, footer }: IProps) => {
    const formField = {
        [FORM_INPUTS.TEXT]: FormText,
        [FORM_INPUTS.TEXTAREA]: FormTextarea,
        [FORM_INPUTS.PASSWORD]: FormPassword,
        [FORM_INPUTS.SELECT]: FormSelect,
        [FORM_INPUTS.MULTI_SELECT]: FormMultiSelect,
        [FORM_INPUTS.COUNTRY_CODE]: FormCountryCode,
        [FORM_INPUTS.PHONE]: FormPhone,
        [FORM_INPUTS.PHONE_NUMBER]: FormPhoneNumber,
        [FORM_INPUTS.RADIO]: FormRadio,
        [FORM_INPUTS.CHECKBOX]: FormCheckbox,
        [FORM_INPUTS.FIELD_SET]: FieldSet,
        [FORM_INPUTS.IMAGE]: FormMedia,
        [FORM_INPUTS.VIDEO]: FormVideo,
        [FORM_INPUTS.SELECT_VIDEO]: FormSelectVideo,
        [FORM_INPUTS.SELECT_IMAGE]: FormSelectImage,
        [FORM_INPUTS.RICH_TEXT_EDITOR]: FormRichTextEditor,
    };

    if (loading) return <InitLoading />;

    const hasColumn = Object.entries(schema).some(([, attr]) => attr.column);
    const leftColumn = Object.entries(schema).filter(([, attr]) => attr.column === "left");
    const centerColumn = Object.entries(schema).filter(([, attr]) => attr.column !== "left" && attr.column !== "right");
    const rightColumn = Object.entries(schema).filter(([, attr]) => attr.column === "right");
    const gridColumnEntry = Object.entries(schema).find(([, attr]) => attr.gridCol);
    const gridColumnNumber = gridColumnEntry ? (gridColumnEntry[1].gridCol ?? 0) : 0;

    return (
        <FormGeneratorStyled noValidate gridColumnNumber={gridColumnNumber} hasColumn={hasColumn} onSubmit={onSubmit}>
            {hasColumn ? (
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        {leftColumn.map(([name, attr]) => {
                            const FormFieldComponent = formField[attr.type as FORM_INPUTS];

                            return (
                                <Controller
                                    key={name}
                                    control={control}
                                    name={name}
                                    render={({ field }) => (
                                        <FormFieldComponent errorMessage={errors[name]} field={field} {...attr} />
                                    )}
                                />
                            );
                        })}
                    </Grid>
                    <Grid item xs={4}>
                        {centerColumn.map(([name, attr]) => {
                            const FormFieldComponent = formField[attr.type as FORM_INPUTS];

                            return (
                                <Controller
                                    key={name}
                                    control={control}
                                    name={name}
                                    render={({ field }) => (
                                        <FormFieldComponent errorMessage={errors[name]} field={field} {...attr} />
                                    )}
                                />
                            );
                        })}
                    </Grid>
                    <Grid item xs={4}>
                        {rightColumn.map(([name, attr]) => {
                            const FormFieldComponent = formField[attr.type as FORM_INPUTS];

                            return (
                                <Controller
                                    key={name}
                                    control={control}
                                    name={name}
                                    render={({ field }) => (
                                        <FormFieldComponent errorMessage={errors[name]} field={field} {...attr} />
                                    )}
                                />
                            );
                        })}
                    </Grid>
                </Grid>
            ) : (
                <>
                    <Grid container spacing={1}>
                        {Object.entries(schema).map(([name, attr]) => {
                            const FormFieldComponent = formField[attr.type as FORM_INPUTS];

                            return (
                                <Grid
                                    key={attr.type + attr.className}
                                    item
                                    style={{ paddingTop: "0px" }}
                                    xs={attr?.gridCol ? attr.gridCol : 12}
                                >
                                    <Controller
                                        key={name}
                                        control={control}
                                        name={name}
                                        render={({ field }) => (
                                            <FormFieldComponent errorMessage={errors[name]} field={field} {...attr} />
                                        )}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </>
            )}

            <FormControl fullWidth margin="normal" variant="outlined">
                {footer}
            </FormControl>
        </FormGeneratorStyled>
    );
};

export default FormGenerator;
