import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";
import { CircularProgress, FormHelperText } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useEffect, useRef, useState } from "react";

import {
    DeleteIconStyled,
    FormVideoInputStyled,
    FormVideoStyled,
    FormVideoUploadStyled,
    FormVideoWrapperStyled,
} from "./styled";

import { VideoFieldSchema } from "@/types/form-generator-schema-type";
import { useVideo } from "@/hooks/useVideo";

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label?: string;
    placeholder: string;
    className?: string;
    errorMessage: FieldError;
    props?: VideoFieldSchema["props"];
}

const FormVideo = ({ field, label, placeholder, className, errorMessage, props = {} }: IProps) => {
    const { previewImage, previewName, ...inputProps } = props || {};
    const inputFileRef = useRef<HTMLInputElement>(null);
    const { uploadVideo, uploadedPercent, assets, loadingAssets } = useVideo();

    const [imageSource, setImageSource] = useState(previewImage || "");
    const [fileName, setFileName] = useState<string | undefined>(previewName || "");

    // const handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    //     if (event.target.files) {
    //         const result = await uploadVideo(event.target.files[0]);
    //         const { videoId, title, assets } = result;

    //         setFileName(title);
    //         assets?.thumbnail && setImageSource(result.assets?.thumbnail || "");
    //         field.onChange(videoId);
    //     }
    // };

    useEffect(() => {
        if (assets) {
            assets?.thumbnail && setImageSource(assets?.thumbnail || "");
        }
    }, [assets]);

    const handleRemove = () => {
        setFileName("");
        field.onChange(undefined);
        setImageSource("");
        if (inputFileRef.current) {
            inputFileRef.current.value = "";
        }
    };

    const handleFocusBack = () => {
        field.onBlur();
        window.removeEventListener("focus", handleFocusBack);
    };

    const fileInputClicked = async (event: React.ChangeEvent<HTMLInputElement>) => {
        let file = event?.target?.files && event.target.files[0];

        if (file) {
            const result = await uploadVideo(file);
            const { videoId, title } = result;

            setFileName(title);

            field.onChange(videoId);
        }
        window.removeEventListener("focus", handleFocusBack);
    };

    const handleClick = () => window.addEventListener("focus", handleFocusBack);

    return (
        <>
            <FormVideoStyled className={className}>
                <label>
                    {label} {props?.required ? <span style={{ color: "red", fontSize: "20px" }}>*</span> : ""}
                </label>
                <FormVideoWrapperStyled>
                    {uploadedPercent && uploadedPercent <= 100 ? (
                        <FormVideoUploadStyled>
                            <CircularProgress />
                            {uploadedPercent ? <span>{uploadedPercent.toFixed(2)}% uploaded</span> : null}
                        </FormVideoUploadStyled>
                    ) : (
                        <>
                            {loadingAssets && (
                                <span className="warning">
                                    Upload Finished. <br />
                                    Trying to load the Thumbnail of this video this going too take a while.
                                </span>
                            )}
                            {imageSource.length ? <img alt={label} src={imageSource} /> : null}
                            {field.value ? <DeleteIconStyled onClick={handleRemove} /> : null}
                            {!fileName?.length && !loadingAssets ? (
                                <FormVideoUploadStyled>
                                    <CloudUploadIcon />
                                    <span>Choose Video ...</span>
                                    {placeholder ? <small>{placeholder}</small> : null}
                                </FormVideoUploadStyled>
                            ) : null}
                        </>
                    )}
                    <FormVideoInputStyled
                        ref={inputFileRef}
                        type="file"
                        onChange={fileInputClicked}
                        onClick={handleClick}
                        {...inputProps}
                    />
                </FormVideoWrapperStyled>
                {fileName ? <span>{fileName?.length ? fileName : null}</span> : null}
            </FormVideoStyled>
            {errorMessage?.message ? (
                <FormHelperText error sx={{ paddingLeft: "20px" }}>
                    {errorMessage?.message}
                </FormHelperText>
            ) : null}
        </>
    );
};

export default FormVideo;
