import { Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

import CustomerFilters, { defaultValues } from "./filters";
import Table from "./table";
import { createData } from "./table/helpers";

import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import { useCustomerList } from "@/api/hooks/useCustomer";
import { ICustomerParams } from "@/api/types/customer";

const CustomerList: React.FC = () => {
    const [params, setParams] = useState<ICustomerParams>({
        search: "",
        skip: 0,
        take: 10,
        filter: {
            active: null,
            emailVerified: null,
            hasSubscription: null,
        },
    });

    const { data: customerData, isFetching, isLoading } = useCustomerList(params);

    const totalItems = customerData?.data.pagination.totalItems;
    const customerRows = useMemo(
        () => (customerData ? customerData.data.items.map((item) => createData(item)) : []),
        [customerData]
    );

    const handleChange = useCallback(({ page, rowsPerPage }: { page?: number, rowsPerPage?: number }) => {
        setParams((prev) => {
            return {
                ...prev,
                skip: Number(page) * Number(rowsPerPage),
                take: rowsPerPage || 10,
            };
        });
    }, []);

    const handleFilterOnApply = useCallback((filterData: typeof defaultValues) => {
        const { search, emailVerified, active, hasSubscription } = filterData;

        setParams((prev) => {
            return {
                ...prev,
                search: String(search) || "",
                filter: {
                    active,
                    emailVerified,
                    hasSubscription,
                },
            };
        });
    }, []);

    return (
        <>
            <DashboardHeader title="Customers">
                <Typography component="p">Search between your customers and find their data and history.</Typography>
                <Typography component="p">Their invoice of their subscription will send to their Email.</Typography>
            </DashboardHeader>
            <DashboardContent>
                <CustomerFilters onApply={handleFilterOnApply} />
                <Table
                    data={customerRows}
                    isFetching={isFetching}
                    isLoading={isLoading}
                    page={Number(params.skip) / Number(params.take)}
                    rowsPerPage={Number(params.take)}
                    totalItems={totalItems || 0}
                    onChange={handleChange}
                />
            </DashboardContent>
        </>
    );
};

export default CustomerList;
