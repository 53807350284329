import { styled } from "@mui/material";

export const CreatePublisherStyled = styled("div")`

    .title {
        font-weight: bold;
        font-size: 20px;
    }

    .add-box {
        margin: 1em auto;
        max-width: 400px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;

        .success {
            margin-inline: 5px;
        }
    }
`;
