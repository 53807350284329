import { Box, Button, Typography, Chip } from "@mui/material";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";

import { SerialCardStyle } from "./style";

import { useContentById, useDeleteContent } from "@/api/hooks/useContents";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { IContentShowResponse } from "@/api/types/content";

interface ISerialBoxProps {
    id: number;
    // eslint-disable-next-line no-unused-vars
    onEdit: (seasonData: IContentShowResponse) => void;
    onSuccess: () => void;
}

export default function SerialBox(props: ISerialBoxProps) {
    const { id, onEdit, onSuccess } = props;
    const { enqueueSnackbar } = useSnackbar();

    const { data } = useContentById(Number(id));

    // const createAccess = checkPermissionAccess(PERMISSIONS.CONTENT_CREATE);
    const editAccess = useCheckPermissionAccess("content", "Update");
    const deleteAccess = useCheckPermissionAccess("content", "Delete");

    const { deleteContent } = useDeleteContent();

    const handleDelete = () => {
        if (deleteAccess) deleteContent(id, { onSuccess: () => onSuccess() });
        if (!deleteAccess) enqueueSnackbar("You don't have access for Deleting this item", { variant: "warning" });
    };

    const handleEdit = () => {
        if (editAccess && data) onEdit(data.data.data);
        if (!editAccess) enqueueSnackbar("You don't have access for Editing this item", { variant: "warning" });
    };

    if (!data) return null;

    const { ml_description, ml_name, season, updatedAt, status } = data.data.data;

    return (
        <SerialCardStyle className="card-style">
            <Box className="card-header">
                <Typography className="session-number">S{season}</Typography>
                <Typography className="name">{ml_name.en}</Typography>
            </Box>
            <Box className="card-content">
                <Typography className="text ">
                    <span className="label">Status:</span>
                    <span className="value">
                        <Chip
                            color={status === "published" ? "info" : "secondary"}
                            label={status}
                            size="small"
                            variant="outlined"
                        />
                    </span>
                </Typography>
                <Typography className="text">
                    <span className="label">Update Date:</span>
                    <span className="value">{dayjs(updatedAt).format("YYYY-MM-DD HH:mm")}</span>
                </Typography>
                <Typography className="text description">
                    <span className="label">Description:</span>
                    <span className="value">{ml_description.en}</span>
                </Typography>
            </Box>
            <Box className="card-footer">
                <Box className="actions">
                    <Button color="error" size="small" variant="contained" onClick={handleDelete}>
                        Delete
                    </Button>
                    <Button color="primary" size="small" variant="contained" onClick={handleEdit}>
                        Edit
                    </Button>
                </Box>
            </Box>
        </SerialCardStyle>
    );
}
