import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React, { useReducer } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { MenuProps } from "@/types/menu";

const MenuItem: React.FC<MenuProps> = ({ href, icon, children, title, permission }) => {
    const { pathname } = useLocation();
    const initState = pathname?.includes(href || "");
    const haveAccess = useCheckPermissionAccess(permission.page, permission.access);

    const [showSubMenu, toggleSubMenu] = useReducer((prev) => !prev, initState);

    const ArrowIcon = showSubMenu ? ExpandLess : ExpandMore;

    if (!haveAccess) return null;

    return (
        <li className={showSubMenu ? "active" : ""}>
            <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to={href || "#"}
                onClick={children?.length ? toggleSubMenu : undefined}
            >
                {icon}
                <span>{title}</span>
            </NavLink>
            {children?.length && (
                <>
                    <ArrowIcon onClick={toggleSubMenu} />
                    <ul data-title={title}>{children}</ul>
                </>
            )}
        </li>
    );
};

export default MenuItem;
