import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";
import { FormHelperText } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useEffect, useState } from "react";

import {
    DeleteIconStyled,
    FormSelectImageStyled,
    FormSelectImageUploadStyled,
    FormSelectImageWrapperStyled,
} from "./styled";

import { SelectImageFieldSchema } from "@/types/form-generator-schema-type";
import { modalActions } from "@/context/modals";
import { MODAL_TYPES } from "@/types/modals";
import { IImageItem } from "@/api/types/image";

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label?: string;
    placeholder: string;
    className?: string;
    errorMessage: FieldError;
    props?: SelectImageFieldSchema["props"];
}
const FormSelectImage = ({ field, label, placeholder, className, errorMessage, props = {} }: IProps) => {
    const { previewImage, previewName } = props || {};

    const [imageSource, setImageSource] = useState(previewImage || "");
    const [fileName, setFileName] = useState<string | undefined>(previewName || "");

    useEffect(() => {
        if (props.previewImage) setImageSource(props.previewImage);
    }, [props.previewImage]);

    const handleRemove = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        e.stopPropagation();
        setFileName("");
        field.onChange(undefined);
        setImageSource("");
    };

    const handleOnChange = (imageItem: IImageItem) => {
        setFileName(imageItem.originalName);
        setImageSource(imageItem.url);
        field.onChange(imageItem.id);
    };

    const openImageModal = () => {
        modalActions.addModal(MODAL_TYPES.SELECT_IMAGE_MODAL, {
            header: "ُSelect Image Modal",
            width: "1100px",
            onChange: handleOnChange,
        });
    };

    return (
        <>
            <FormSelectImageStyled className={className}>
                <label>
                    {label} {props?.required ? <span style={{ color: "red", fontSize: "20px" }}>*</span> : ""}
                </label>
                <FormSelectImageWrapperStyled onClick={openImageModal}>
                    {imageSource.length ? <img alt={label} src={imageSource} /> : null}
                    {field.value ? <DeleteIconStyled onClick={handleRemove} /> : null}
                    {!fileName?.length && !imageSource.length ? (
                        <FormSelectImageUploadStyled>
                            <CloudUploadIcon />
                            <span>Choose Image ...</span>
                            {placeholder ? <small>{placeholder}</small> : null}
                        </FormSelectImageUploadStyled>
                    ) : null}
                </FormSelectImageWrapperStyled>
                {fileName ? <span>{fileName?.length ? fileName : null}</span> : null}
            </FormSelectImageStyled>
            {errorMessage?.message ? <FormHelperText error>{errorMessage?.message}</FormHelperText> : null}
        </>
    );
};

export default FormSelectImage;
