import AxiosInstance from "@/api";
import { IGetUIListResponse, IUIDeleteResponse, IGetUIsBody, IUIUpdateBody } from "@/api/types/home-ui";
import { API_ROUTES } from "@/constants/api-routes";
import { serializeObject } from "@/utils/serializer";

export const GetUIs = async ({ params }: { params: IGetUIsBody }) => {
    const result = await AxiosInstance.get<IGetUIListResponse>(`${API_ROUTES.HOME.LIST}?${serializeObject(params)}`);

    return result.data;
};

export const DeleteUI = async (id: number) => {
    const result = await AxiosInstance.delete<IUIDeleteResponse>(API_ROUTES.HOME.DELETE(id));

    return result.data;
};

export const UpdateUI = async (id: number, body: Omit<IUIUpdateBody, "id">) => {
    const result = await AxiosInstance.put<IGetUIListResponse>(API_ROUTES.HOME.UPDATE(id), body);

    return result.data;
};
