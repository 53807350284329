import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { VideoUploader, VideoUploadResponse } from "@api.video/video-uploader";

import { getAxiosError } from "../utils/get-axios-error";

import { videoService } from "@/api/services/video";

export const useVideo = () => {
    const [uploadLoading, setLoading] = useState(false);
    const [loadingAssets, setLoadingAssets] = useState(false);
    const [assets, setAssets] = useState<VideoUploadResponse["assets"] | null>(null);
    const [uploadedPercent, setUploadedPercent] = useState<number>();

    const getToken = async () => {
        try {
            setLoading(true);
            const response = await videoService.token();

            return response.data.data;
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";

            setLoading(false);
            enqueueSnackbar(message, { variant: "error" });
            throw err;
        }
    };

    const uploadVideo = async (file: File): Promise<VideoUploadResponse> => {
        try {
            const { token } = await getToken();
            const uploader = new VideoUploader({
                file,
                uploadToken: token,
            });

            uploader.onPlayable((data) => {
                setAssets(data.assets);
                setLoadingAssets(false);
            });

            uploader.onProgress((event) => {
                const percentComplete = (event.uploadedBytes / event.totalBytes) * 100;

                if (percentComplete >= 100) {
                    setLoading(false);
                    setLoadingAssets(true);
                }

                setUploadedPercent(percentComplete);
            });

            return uploader.upload();
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";

            enqueueSnackbar(message, { variant: "error" });
            throw err;
        }
    };

    return {
        assets,
        loadingAssets,
        loading: uploadLoading,
        uploadedPercent,
        uploadVideo,
    };
};
