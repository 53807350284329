import React from "react";
import { Box, Container, Card, CardContent, Typography, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Tag, tagMapping } from "@/constants/enums/home-page-ui";
import ROUTE_CONSTANTS from "@/constants/route-constants";

interface SectionCardProps {
    section: {
        type?: string,
        tag?: Tag,
    };
    index: number;
    handleDeleteSection: (index: number) => void;
}

const SectionCard: React.FC<SectionCardProps> = ({ section, index, handleDeleteSection }) => {
    return (
        <Container>
            <Card>
                <CardContent sx={{ display: "flex", justifyContent: "space-between", px: 15 }}>
                    <Typography>{section.type || "Untitled"}</Typography>
                    <Typography>{tagMapping[section.tag as Tag]}</Typography>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <IconButton onClick={() => handleDeleteSection(index)} aria-label="delete">
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                        <Link to={ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.UPDATE_SECTION.EDIT_BY_ID(index).ABSOLUTE}>
                            <IconButton aria-label="edit">
                                <EditIcon color="action" fontSize="small" />
                            </IconButton>
                        </Link>
                    </Box>
                </CardContent>
            </Card>
        </Container>
    );
};

export default SectionCard;
