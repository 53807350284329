import axios from "axios";

import SnackbarUtils from "@/utils/stackbarUtil";

export const errorCallback = (error: Error) => {
    // check if the error was thrown from axios
    if (axios.isAxiosError(error)) {
        // do something
        // or just re-throw the error
        SnackbarUtils.error(error.response?.data.meta.message);
    } else {
        // do something else
        // or creating a new error
        SnackbarUtils.error(error.message);
    }
};
