import { useForm } from "react-hook-form";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";

import { IImageCreateFormType } from "./form-type";
import { imageSchema } from "./schema";
import { createImageValidationSchema } from "./validation";

import { generateDefaultValueFromFormSchema } from "@/utils/generate-default-value-from-form-schema";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { getAxiosError } from "@/utils/get-axios-error";
import FormGenerator from "@/components/kit/FormGenerator";
import DashboardHeader from "@/components/app/DashboardHeader";
import DashboardContent from "@/components/app/DashboardContent";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { useCreateImage } from "@/api/hooks/useImage";

const ImageForm: React.FC = () => {
    const navigate = useNavigate();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const listAccess = useCheckPermissionAccess("image", "List");

    const { mutate: createImage } = useCreateImage();

    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
    } = useForm<IImageCreateFormType>({
        defaultValues: generateDefaultValueFromFormSchema(imageSchema),
        mode: "all",
        resolver: yupResolver(createImageValidationSchema),
    });

    const onSubmit = async (_val: IImageCreateFormType) => {
        try {
            setSubmitLoading(true);
            const formData = new FormData();

            formData.append("file", _val.image);
            formData.append("title", _val.title);
            createImage(
                { body: formData },
                {
                    onSuccess: () => {
                        enqueueSnackbar(`Image created successfully`, { variant: "success" });
                        navigate(ROUTE_CONSTANTS.MEDIA_MANAGEMENT.IMAGES.ROOT.ABSOLUTE);
                    },
                }
            );
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";

            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setSubmitLoading(false);
        }
    };

    const footer = (
        <Stack direction={"row"} paddingBottom={10} spacing={2}>
            <LoadingButton disabled={!isValid || submitLoading} loading={submitLoading} type="submit" variant="contained">
                Create Image
            </LoadingButton>
            <Button type="button" onClick={() => navigate(-1)}>
                Cancel
            </Button>
        </Stack>
    );

    return (
        <>
            <DashboardHeader
                title="Create Image"
                {...(listAccess
                    ? {
                          titleSuffix: (
                              <Stack direction={"row"} spacing={2}>
                                  <Button component={Link} to={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.IMAGES.ROOT.ABSOLUTE}>
                                      Back To Image List
                                  </Button>
                              </Stack>
                          ),
                      }
                    : undefined)}
            />
            <DashboardContent>
                <FormGenerator
                    control={control}
                    errors={errors}
                    footer={footer}
                    schema={imageSchema}
                    onSubmit={handleSubmit(onSubmit)}
                />
            </DashboardContent>
        </>
    );
};

export default ImageForm;
