import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import { TableCell, TableRow } from "@mui/material";

import { EnhancedTableProps, HeadCell } from "./type";

export default function EnhancedTableHead<T>(props: EnhancedTableProps<T>) {
    const { order, orderBy, headCells, onRequestSort, hasAction = true } = props;
    const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead component="div">
            <TableRow component="div">
                {headCells.map((headCell: HeadCell<T>) => (
                    <TableCell
                        key={headCell.id as string}
                        align="left"
                        component="div"
                        padding="normal"
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            component="div"
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {hasAction && (
                    <TableCell align="right" component="div" padding="normal" sortDirection={false}>
                        Actions
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    );
}
