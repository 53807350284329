import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

import { FilterBody } from "../types";
import { IUIUpdateBody } from "../types/home-ui";

import { errorCallback } from "@/api/hooks/error";
import { DeleteUI, GetUIs, UpdateUI } from "@/api/services/home-ui";

export function useGetUIs(params: FilterBody) {
    return useQuery({
        queryKey: ["HOME_UI", params],
        queryFn: () => GetUIs({ params }),
    });
}

export function useDeleteUI() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (id: number) => DeleteUI(id),
        onError: errorCallback,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["HOME_UI"] });
            enqueueSnackbar("The UI Deleted Successfully", { variant: "success" });
        },
    });
}

export function useUpdateUI() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ id, body }: { id: number, body: Omit<Partial<IUIUpdateBody>, "id"> }) => UpdateUI(id, body),
        onError: errorCallback,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["HOME_UI"] });
            enqueueSnackbar("The UI Updated Successfully", { variant: "success" });
        },
    });
}
