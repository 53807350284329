import { Button, Typography } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { Dispatch, SetStateAction, useState } from "react";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

import { staticListColumnSchema } from "./column-schema";

import { getAxiosError } from "@/utils/get-axios-error";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import DashboardHeader from "@/components/app/DashboardHeader";
import DashboardContent from "@/components/app/DashboardContent";
import { ITableParams } from "@/types/table";
import Table from "@/components/kit/Table";
import { modalActions } from "@/context/modals";
import { MODAL_TYPES } from "@/types/modals";
import { staticService } from "@/api/services/static";
import { IStaticListResponse } from "@/api/types/static";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";

const service = async (queryParams?: ITableParams) => staticService.list(queryParams);

const StaticList: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const createAccess = useCheckPermissionAccess("static", "Create");
    const editAccess = useCheckPermissionAccess("static", "Update");
    const deleteAccess = useCheckPermissionAccess("static", "Delete");

    const suffixCols = (
        tableData: IStaticListResponse[],
        setTableData: Dispatch<SetStateAction<IStaticListResponse[]>>
    ): GridColDef[] => {
        const handleDelete = (id: number) => {
            const handleAction = async () => {
                try {
                    setLoading(true);
                    await staticService.delete(id);
                    enqueueSnackbar(`Static page deleted successfully`, { variant: "success" });
                    setTableData((prev) => (prev ? prev.filter((item) => item.id !== id) : prev));
                } catch (err) {
                    const error = getAxiosError(err);
                    const message = error?.meta.message || "Server Error";

                    enqueueSnackbar(message, { variant: "error" });
                } finally {
                    setLoading(false);
                }
            };

            modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
                header: "Delete Confirmation",
                text: "Are you sure to delete this static page?",
                handleAction,
            });
        };

        return [
            {
                field: "actions",
                type: "actions",
                headerName: "Actions",
                filterable: false,
                hideable: false,
                resizable: false,
                flex: 1,
                align: "center",
                sortable: false,
                getActions: (params: GridRowParams<IStaticListResponse>) => {
                    return [
                        ...(editAccess
                            ? [
                                  <Link
                                      key={params.row.id}
                                      to={ROUTE_CONSTANTS.SETTINGS.STATIC.UPDATE.EDIT_BY_ID(params.row.id).ABSOLUTE}
                                  >
                                      <GridActionsCellItem color="inherit" icon={<EditIcon color="action" />} label="Edit" />
                                  </Link>,
                              ]
                            : []),
                        ...(deleteAccess
                            ? [
                                  <GridActionsCellItem
                                      key={params.row.id}
                                      color="inherit"
                                      icon={<DeleteIcon color="action" />}
                                      label="Delete"
                                      onClick={() => handleDelete(params.row.id)}
                                  />,
                              ]
                            : []),
                    ];
                },
            },
        ];
    };

    return (
        <>
            <DashboardHeader
                title="Static Pages"
                titleSuffix={
                    createAccess ? (
                        <Button
                            color="primary"
                            component={Link}
                            to={ROUTE_CONSTANTS.SETTINGS.STATIC.ADD_NEW.ROOT.ABSOLUTE}
                            variant="contained"
                        >
                            Add New Static Page
                        </Button>
                    ) : null
                }
            >
                <Typography component="p">Define your static pages to show on the app</Typography>
            </DashboardHeader>

            <DashboardContent>
                <Table
                    columnsSchema={staticListColumnSchema}
                    loading={loading}
                    service={service}
                    suffixCols={editAccess || deleteAccess ? suffixCols : undefined}
                />
            </DashboardContent>
        </>
    );
};

export default StaticList;
