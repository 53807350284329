import dayjs from "dayjs";

import { Data } from "./paymentHistory/type";

import { IPaymentItem } from "@/api/types/payments";

export function createData(data: IPaymentItem): Data {
    const { id, method, planName, source, updatedAt, status, amount } = data;

    return {
        id,
        method,
        planName,
        source,
        status,
        amount,
        updatedAt: dayjs(updatedAt).format("YYYY-MM-DD HH:mm"),
    };
}
