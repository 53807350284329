import * as yup from "yup";

export default yup.object().shape({
    en_title: yup.string().min(2, "English title must be at most 2 characters").required("English title is required"),
    ar_title: yup.string().min(2, "Arabic title must be at most 2 characters").required("Arabic title is required"),

    en_description: yup.string().max(600, "English description must be at least 600 characters"),
    ar_description: yup.string().max(600, "Arabic description must be at least 600 characters"),

    mainVideo: yup
        .number()
        .nullable()
        .when("sharing_state", {
            is: (value: number) => Number(value) === 2,
            then: () => yup.number().nonNullable().required("You Must Select a main Video"),
        }),
    trailerVideo: yup.number().nullable(),
    thumbnailPortraitId: yup
        .number()
        .nullable()
        .when("sharing_state", {
            is: (value: number) => Number(value) === 2,
            then: () => yup.number().nonNullable().required("You Must Select Portrait Image"),
        }),

    thumbnailLandscapeId: yup
        .number()
        .nullable()
        .when("sharing_state", {
            is: (value: number) => Number(value) === 2,
            then: () => yup.number().nonNullable().required("You Must Select Landscape Image"),
        }),

    episode: yup
        .number()
        .positive()
        .when("autoId", ([autoId], schema) => {
            return autoId ? schema : schema.required("This is required");
        }),
    autoId: yup.boolean().required("required"),
    sharing_state: yup.number().required("required"),
});
