import dayjs from "dayjs";

import { Data } from "./types";

import { ICustomer } from "@/api/types/customer";

export function createData(data: ICustomer): Data {
    const { id, firstName, lastName, phoneNumber, emailVerified, email, state, updatedAt, hasSubscription } = data;

    return {
        id,
        firstName,
        lastName,
        phoneNumber,
        emailVerified,
        email: email || "",
        state,
        hasSubscription,
        updatedAt: dayjs(updatedAt).format("YYYY-MM-DD HH:mm"),
    };
}
