import { IFormGeneratorSchemaType } from "@/types/form-generator-schema-type";
import { FORM_INPUTS } from "@/types/form-inputs";

export type GenreFormSchemaFields = "en_title" | "ar_title" | "slug" | "en_description" | "ar_description" | "category";

export type IGenreFormSchema = Record<GenreFormSchemaFields, IFormGeneratorSchemaType>;

export const genreSchema: IGenreFormSchema = {
    en_title: {
        label: "English Title",
        placeholder: "English Title",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
        },
    },
    slug: {
        label: "Slug",
        placeholder: "Slug",
        type: FORM_INPUTS.TEXT,
    },
    ar_title: {
        label: "Arabic Title",
        placeholder: "Arabic Title",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
            dir: "rtl",
        },
    },
    en_description: {
        label: "English Description",
        placeholder: "English Description",
        type: FORM_INPUTS.TEXTAREA,
        props: {
            required: true,
        },
    },
    ar_description: {
        label: "Arabic Description",
        placeholder: "Arabic Description",
        type: FORM_INPUTS.TEXTAREA,
        props: {
            required: true,
            dir: "rtl",
        },
    },
    category: {
        label: "Category",
        placeholder: "Category",
        type: FORM_INPUTS.RADIO,
        defaultValue: 1,
        data: [
            { value: 1, label: "Education" },
            { value: 2, label: "Entertainment" },
        ],
        props: {
            required: true,
        },
    },
};
