import React, { Fragment } from "react";

import { IMenuData } from "./types";
import MenuItem from "./SidebarMenuItem";
import menuData from "./menu-data";
import { SidebarMenuItemsStyled } from "./styled";

import { MenuProps } from "@/types/menu";
import { MENU_VIEW } from "@/constants/enums/menu";
import { useMenuView } from "@/context/menu";

const SidebarMenu: React.FC = () => {
    const menuView = useMenuView();
    const recursivelyRenderMenuItem = (childrenData: IMenuData) => {
        const { children } = childrenData;

        const componentProps = {
            ...childrenData,
            ...{
                children:
                    children &&
                    children.map((innerChildrenData: IMenuData) => (
                        <Fragment key={innerChildrenData.id}>{recursivelyRenderMenuItem(innerChildrenData)}</Fragment>
                    )),
            },
        } as MenuProps;

        return <MenuItem {...componentProps} />;
    };

    return (
        <SidebarMenuItemsStyled className={menuView === MENU_VIEW.MINIMIZE ? "minimized" : ""}>
            <ul>
                {menuData.map((menuItems: IMenuData) => (
                    <Fragment key={menuItems.id}>{recursivelyRenderMenuItem(menuItems)}</Fragment>
                ))}
            </ul>
        </SidebarMenuItemsStyled>
    );
};

export default SidebarMenu;
