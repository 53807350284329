import { FormControl, FormHelperText, TextField } from "@mui/material";
import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";

import { TextFieldSchema } from "@/types/form-generator-schema-type";

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label?: string;
    placeholder: string;
    className?: string;
    errorMessage: FieldError;
    props?: TextFieldSchema["props"];
}

export default function FormPhone({ className, field, placeholder, errorMessage, label, props }: IProps) {
    return (
        <FormControl fullWidth className={className} margin="normal" variant="outlined">
            <TextField
                {...field}
                {...props}
                fullWidth
                defaultValue={field.value}
                label={`${label}${props?.required ? " *" : ""}`}
                placeholder={placeholder}
                value={field.value}
                variant="outlined"
                onChange={(e) => field.onChange(e.target.value)}
            />
            {errorMessage?.message ? <FormHelperText error>{errorMessage?.message}</FormHelperText> : null}
        </FormControl>
    );
}
