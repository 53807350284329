import { useEffect, useState } from "react";

import { USER_ROLE } from "@/constants/enums/user";
import { useUserPermissions, useUser } from "@/context/authContext";
import { Access } from "@/constants/enums/permissions";

export const useCheckPermissionAccess = (page: string | string[], access: Access) => {
    const userPermissions = useUserPermissions();
    const user = useUser();
    const [haveAccess, setAccess] = useState<boolean | null>(user?.role === USER_ROLE.ADMINISTRATOR ? true : null);

    useEffect(() => {
        if (user?.role === USER_ROLE.ADMINISTRATOR) {
            setAccess(true);
        } else if (userPermissions) {
            if (Array.isArray(page)) {
                // do have any of those pages in permission object ?
                // if we have at least on page we should view the section
                // an in section we must check for access again
                page.forEach((eachPage) => {
                    if (userPermissions[eachPage]) {
                        if (!haveAccess) setAccess(true);
                    }
                });
            } else {
                // we check for page access
                // then check for operation access
                const section = userPermissions[page];

                if (section && section.includes(access)) {
                    setAccess(true);
                } else {
                    setAccess(false);
                }
            }
        } else {
            setAccess(false);
        }
    }, [page, user?.role, access, userPermissions, haveAccess]);

    return haveAccess;
};
