import React from "react";
import { Person } from "@mui/icons-material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Box, SvgIcon } from "@mui/material";

import { HeaderLogoutStyled, HeaderProfileStyled, HeaderStyled, HeaderThemeStyled, HeaderThemeSwitchStyled } from "./styled";

import { useMenuView } from "@/context/menu";
import { MENU_VIEW } from "@/constants/enums/menu";
import { useAuth } from "@/hooks/useAuth";
import { useUser } from "@/context/authContext";
import { useThemeStore } from "@/context/theme";
import { USER_ROLE } from "@/constants/enums/user";

const Header: React.FC = () => {
    const user = useUser();
    const { logOut } = useAuth();
    const menuView = useMenuView();
    const { theme, setTheme } = useThemeStore();

    const isMinimizedMenu = menuView === MENU_VIEW.MINIMIZE;
    const minimizedClassName = isMinimizedMenu ? "minimize" : "";

    const toggleDarkMode = () => setTheme(theme === "dark" ? "light" : "dark");

    return (
        <HeaderStyled className={minimizedClassName}>
            <Box className="user-info">
                <HeaderProfileStyled>
                    <SvgIcon className="svg" component={Person} />
                    {user?.role === USER_ROLE.ADMINISTRATOR ? (
                        <span>{user?.role}</span>
                    ) : (
                        <span className="ml-2">
                            {user?.firstName} {user?.lastName} ({user?.role})
                        </span>
                    )}
                </HeaderProfileStyled>
            </Box>
            <HeaderLogoutStyled>
                <HeaderThemeStyled>
                    <HeaderThemeSwitchStyled checked={theme === "dark"} onClick={toggleDarkMode} />
                </HeaderThemeStyled>
                <PowerSettingsNewIcon onClick={logOut}>Logout</PowerSettingsNewIcon>
            </HeaderLogoutStyled>
        </HeaderStyled>
    );
};

export default Header;
