import { Box, Button, Divider, Typography } from "@mui/material";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useSnackbar } from "notistack";

import CreatePlanForm, { ICreatePlanForm } from "../partials/form";

import EditPlanStyle from "./style";

import { useUpdatePlan } from "@/api/hooks/usePlans";
import { AvailableCurrency } from "@/api/types";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import ROUTE_CONSTANTS from "@/constants/route-constants";

export default function CreatePlan() {
    const { planId } = useParams();
    const { mutate: updatePlan } = useUpdatePlan();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const editAccess = useCheckPermissionAccess("plan", "Update");

    const handleSubmit = (formData: ICreatePlanForm) => {
        const { en_title, ar_title, en_description, ar_description } = formData;

        if (editAccess) {
            updatePlan(
                {
                    params: { currency: formData.currency as AvailableCurrency, id: Number(planId) },
                    body: {
                        ...formData,
                        ml_title: { en: en_title, ar: ar_title },
                        ml_description: { en: en_description, ar: ar_description },
                    },
                },
                {
                    onSuccess: () => {
                        navigate(-1);
                    },
                }
            );
        } else {
            enqueueSnackbar("You don't have the correct rights to Edit a plan.", { variant: "warning" });
        }
    };

    return (
        <EditPlanStyle>
            <Box className="plans-header">
                <Typography className="main-title">Edit a Plan</Typography>
                <Button component={Link} to={ROUTE_CONSTANTS.SETTINGS.PLANS.ROOT.ABSOLUTE}>
                    Back To List
                </Button>
            </Box>
            <Divider />
            <Box className="form">
                <CreatePlanForm planId={Number(planId)} onSubmit={handleSubmit} />
            </Box>
        </EditPlanStyle>
    );
}
