import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";

import { ModalsList } from "./modal-list";
import { ModalContentStyled, ModalHeaderStyled, ModalWrapperStyled } from "./styled";

import { IModalProps } from "@/types/modals";
import { modalActions, useModals } from "@/context/modals";

const ModalCreator = () => {
    const navigate = useNavigate();
    const modals = useModals((s) => s.modals);
    const [hardwareBackHistory, setHardwareBackHistory] = useState(true);

    const closeEvent = useCallback(() => {
        modalActions.removeLastModal();
    }, []);

    const close = useCallback(
        (clearModals?: boolean) => {
            setHardwareBackHistory(false);
            if (clearModals) {
                modalActions.clearModals();
            } else {
                navigate(-1);
                modalActions.removeLastModal();
            }
            setTimeout(() => {
                setHardwareBackHistory(true);
            }, 0);
        },
        [navigate]
    );

    useEffect(() => {
        if (modals.length && hardwareBackHistory) {
            window.addEventListener("popstate", closeEvent);
        }

        return () => window.removeEventListener("popstate", closeEvent);
    }, [modals, hardwareBackHistory, closeEvent]);

    return (
        <>
            {modals.map((modal, index) => {
                const ModalComponent = ModalsList[modal.type] as React.FC<IModalProps>;
                const modalHeader = modal.data.header || undefined;
                const modalWidth = modal.data.width || undefined;

                return (
                    <Modal
                        key={index}
                        closeAfterTransition
                        aria-describedby={`transition-modal-description-${index}`}
                        aria-labelledby={`transition-modal-title-${index}`}
                        open={true}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                        slots={{ backdrop: Backdrop }}
                        onClose={() => close()}
                    >
                        <Fade in={true}>
                            <ModalWrapperStyled width={modalWidth}>
                                <ModalHeaderStyled>
                                    {modalHeader ? <h5>{modalHeader}</h5> : null}
                                    <CloseIcon onClick={() => close()} />
                                </ModalHeaderStyled>
                                <ModalContentStyled>
                                    <ModalComponent closeModal={close} data={modal.data} />
                                </ModalContentStyled>
                            </ModalWrapperStyled>
                        </Fade>
                    </Modal>
                );
            })}
        </>
    );
};

export default ModalCreator;
