import { Navigate } from "react-router-dom";

import { getRefreshToken } from "@/utils/storageFactory";
import ROUTE_CONSTANTS from "@/constants/route-constants";

export default function withAuth<P>(WrappedComponent: React.ComponentType<P>) {
    return function CheckPermissions(props: P & {}) {
        const refreshToken = getRefreshToken();

        return refreshToken ? <WrappedComponent {...props} /> : <Navigate to={ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE} />;
    };
}
