import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

import { CustomerGetList, CustomerBlock, CustomerGetById, CustomerUpdate } from "../services/customer";
import { ICustomerBlockPayload, ICustomerParams, ICustomerUpdatePayload } from "../types/customer";

export function useCustomerList(params: ICustomerParams) {
    return useQuery({ queryKey: ["Customers", params], queryFn: () => CustomerGetList(params) });
}

export function useCustomerById(id: number) {
    return useQuery({ queryKey: ["Customers", id], queryFn: () => CustomerGetById(id) });
}

export function useCustomerBlockAction() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationKey: ["CustomerBlock"],
        mutationFn: ({ id, body }: { id: number, body: ICustomerBlockPayload }) => CustomerBlock(id, body),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["Customers"] });
            enqueueSnackbar("The Customer State has been changed Successfully", { variant: "success" });
        },
    });
}

export function useCustomerUpdate() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationKey: ["CustomerUpdate"],
        mutationFn: ({ id, body }: { id: number, body: ICustomerUpdatePayload }) => CustomerUpdate(id, body),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["Customers"] });
            enqueueSnackbar("The Customer Updated Successfully", { variant: "success" });
        },
    });
}
