import { API_ROUTES } from "@/constants/api-routes";
import AxiosInstance from "@/api";
import { ImageFilterBody, IImageListResponse, IImageUploadResponse, IImageDeleteResponse } from "@/api/types/image";
import { serializeObject } from "@/utils/serializer";

export const GetImageListPost = async (params: ImageFilterBody) => {
    const result = await AxiosInstance.post<IImageListResponse>(API_ROUTES.IMAGE.LIST, params);

    return result.data;
};

export const GetImageList = async (params: ImageFilterBody) => {
    const result = await AxiosInstance.get<IImageListResponse>(`${API_ROUTES.IMAGE.LIST}?${serializeObject(params)}`);

    return result.data;
};

export const GetImageById = async (id: number) => {
    const result = await AxiosInstance.get<IImageListResponse>(`${API_ROUTES.IMAGE.GET_BY_ID(id)}/${id}`);

    return result.data;
};

export const CreateImage = async (payload?: FormData) => {
    const result = await AxiosInstance.post<IImageUploadResponse>(API_ROUTES.IMAGE.CREATE, payload, {
        headers: { "Content-Type": "multipart/form-data" },
    });

    return result.data;
};

export const DeleteImage = async (id: number) => {
    const result = await AxiosInstance.delete<IImageDeleteResponse>(API_ROUTES.IMAGE.DELETE(id));

    return result.data;
};
