import { useCallback, useRef, useState } from "react";
import { Button, CircularProgress, InputAdornment, OutlinedInput, Pagination, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    SelectImageModalFooterButtonsStyled,
    SelectImageModalFooterPaginationStyled,
    SelectImageModalFooterStyled,
    SelectImageModalItemContentStyled,
    SelectImageModalItemHeaderStyled,
    SelectImageModalItemStyled,
    SelectImageModalListBodyLoadingStyled,
    SelectImageModalListBodyStyled,
    SelectImageModalListHeaderStyled,
    SelectImageModalListWrapperStyled,
    SelectImageModalPreviewStyled,
    SelectImageModalStyled,
} from "./styled";

import { IModalProps } from "@/types/modals";
import { IImageItem, ImageFilterBody } from "@/api/types/image";
import { IMAGE_MODAL_PER_PAGE } from "@/constants/image-modal-configs";
import { stringLimiter } from "@/utils/string-limiter";
import { useGetImageListPost } from "@/api/hooks/useImage";

export interface SelectImageModalProps {
    preview?: boolean;
    // eslint-disable-next-line no-unused-vars
    onChange: (...event: any[]) => void;
}
const SelectImageModal: React.FC<IModalProps<SelectImageModalProps>> = ({ data: { onChange }, closeModal }) => {
    const [searchLoading, setSearchLoading] = useState(false);
    const [queryParams, setQueryParams] = useState<ImageFilterBody>({ skip: 0, take: 15, search: "" });
    const [selectedImage, setSelectedImage] = useState<IImageItem>();
    const [search, setSearch] = useState("");
    const refTimer = useRef<ReturnType<typeof setTimeout>>();

    const { data, pagination, isLoading: listLoading } = useGetImageListPost(queryParams);

    const handlePaginationModelChange = useCallback((page: number) => {
        setQueryParams((prev) => ({ ...prev, take: IMAGE_MODAL_PER_PAGE, skip: (page - 1) * IMAGE_MODAL_PER_PAGE }));
    }, []);

    const onSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        var searchText = e.target.value; // this is the search text

        if (refTimer.current) clearTimeout(refTimer.current);
        setSearch(searchText);

        refTimer.current = setTimeout(() => {
            //search function
            setQueryParams((prev) => ({ ...prev, search: e.target.value }));
            setSearchLoading(true);
        }, 300);
    };

    const clearSearch = () => {
        setQueryParams((prev) => ({ ...prev, search: "" }));
        setSearchLoading(true);
    };

    const handleSelectImage = (imageIndexId: number) => {
        const image = data && data.data.items?.find((item) => item.id === imageIndexId);

        if (image) setSelectedImage(image);
    };
    const handleSelectImageForm = () => {
        onChange(selectedImage);
        closeModal();
    };

    return (
        <>
            <SelectImageModalStyled>
                <SelectImageModalListWrapperStyled>
                    <SelectImageModalListHeaderStyled>
                        <OutlinedInput
                            endAdornment={
                                <InputAdornment position="end">
                                    {searchLoading ? (
                                        <CircularProgress size={24} />
                                    ) : queryParams?.search ? (
                                        <CloseIcon sx={{ cursor: "pointer" }} onClick={clearSearch} />
                                    ) : null}
                                </InputAdornment>
                            }
                            placeholder="Image Search ..."
                            size="small"
                            value={search}
                            onChange={onSearch}
                        />
                    </SelectImageModalListHeaderStyled>
                    {listLoading ? (
                        <SelectImageModalListBodyLoadingStyled>
                            <CircularProgress size={24} />
                        </SelectImageModalListBodyLoadingStyled>
                    ) : (
                        <SelectImageModalListBodyStyled>
                            {data?.data.items?.map((item) => {
                                return (
                                    <SelectImageModalItemStyled
                                        key={item.id}
                                        active={selectedImage?.id === item.id}
                                        onClick={() => handleSelectImage(item.id)}
                                    >
                                        <SelectImageModalItemHeaderStyled>
                                            {item.url ? <img alt={item.originalName} src={item.url} /> : <>No Image</>}
                                        </SelectImageModalItemHeaderStyled>
                                        <SelectImageModalItemContentStyled>
                                            <Typography>
                                                <span className="label">File Name: </span>
                                                <span className="value">
                                                    {stringLimiter(
                                                        item.originalName.length ? item.originalName : item.originalName,
                                                        40
                                                    )}
                                                </span>
                                            </Typography>
                                            <Typography>
                                                <span className="label">Title:</span>
                                                <span className="value">
                                                    {stringLimiter(item.title.length ? item.title : item.title, 40)}
                                                </span>
                                            </Typography>
                                        </SelectImageModalItemContentStyled>
                                    </SelectImageModalItemStyled>
                                );
                            })}
                        </SelectImageModalListBodyStyled>
                    )}
                </SelectImageModalListWrapperStyled>
                <SelectImageModalPreviewStyled>
                    {selectedImage ? (
                        <img alt={selectedImage.originalName} src={selectedImage.url} />
                    ) : (
                        <span>No image has been selected yet</span>
                    )}
                </SelectImageModalPreviewStyled>
            </SelectImageModalStyled>
            <SelectImageModalFooterStyled>
                {pagination && pagination?.totalItems > IMAGE_MODAL_PER_PAGE ? (
                    <SelectImageModalFooterPaginationStyled>
                        <Pagination
                            count={pagination.totalPages}
                            disabled={listLoading}
                            page={pagination.currentPage}
                            onChange={(e, page) => handlePaginationModelChange(page)}
                        />
                    </SelectImageModalFooterPaginationStyled>
                ) : null}
                <SelectImageModalFooterButtonsStyled>
                    <Button disabled={!selectedImage} variant="contained" onClick={handleSelectImageForm}>
                        Select Image
                    </Button>
                </SelectImageModalFooterButtonsStyled>
            </SelectImageModalFooterStyled>
        </>
    );
};

export default SelectImageModal;
