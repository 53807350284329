import { useMemo, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { DeleteOutline, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Data } from "../types";

import { TableWrapperStyle } from "./style";
import headCells from "./tableHeadCells";

import TableSkeleton from "@/components/app/TableSkeleton";
import { getComparator, Order } from "@/utils/table-utils";
import EnhancedTableHead from "@/components/app/EnhancedTableHeader";
import ConfirmationModal from "@/components/app/modalVariants/confirmation";
import { useDeletePublisher } from "@/api/hooks/publisher";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import Loading from "@/components/app/Loading/tableLoading";

export default function PublisherTable({
    data: rows,
    totalItems,
    rowsPerPage,
    page,
    isLoading,
    isFetching,
    onChange,
}: {
    data: Data[],
    totalItems: number,
    page: number,
    isLoading: boolean,
    isFetching: boolean,
    rowsPerPage: number,
    // eslint-disable-next-line no-unused-vars
    onChange: (data: { page?: number, rowsPerPage?: number }) => void,
}) {
    const navigate = useNavigate();
    const [order, setOrder] = useState<Order>("desc");
    const [orderBy, setOrderBy] = useState<keyof Data>("updatedAt");
    const [confirmModalId, setConfirmModal] = useState<number | null>(null);

    const { mutate: deletePublisher, isPending } = useDeletePublisher();

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === "asc";

        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        onChange({ page: newPage, rowsPerPage });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
    };

    const handleEdit = (id: number) =>
        navigate(ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.UPDATE_PUBLISHER.EDIT_BY_ID(id).ABSOLUTE);

    const handleClose = () => setConfirmModal(null);
    const handleDelete = () =>
        confirmModalId &&
        deletePublisher(
            { id: confirmModalId },
            {
                onSuccess: () => {
                    setConfirmModal(null);
                },
            }
        );

    const visibleRows = useMemo(() => [...rows].sort(getComparator(order, orderBy)), [order, orderBy, rows]);

    return (
        <TableWrapperStyle className="table-style-wrapper" sx={{ width: "100%", marginTop: "2em" }}>
            <TableContainer sx={{ maxHeight: "700px" }}>
                <Table
                    stickyHeader
                    aria-labelledby="tableTitle"
                    className="table"
                    component="div"
                    size="medium"
                    sx={{ padding: ".5em" }}
                >
                    <EnhancedTableHead<Data>
                        headCells={headCells}
                        order={order}
                        orderBy={orderBy}
                        rowCount={rows.length}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody component="div" sx={{ position: "relative" }}>
                        {isLoading && <TableSkeleton cells={headCells.map((item) => item.id)} />}
                        {!isLoading && !isFetching && visibleRows.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={headCells.length}>No Data To Show</TableCell>
                            </TableRow>
                        )}
                        {isFetching && <Loading />}
                        {visibleRows.map((row) => {
                            return (
                                <TableRow key={row.id} className="table-row" component="div" role="checkbox" tabIndex={-1}>
                                    <TableCell align="center" className="table-cell" component="div">
                                        <span className="table-value">{row.id}</span>
                                    </TableCell>
                                    <TableCell align="center" className="table-cell" component="div" sx={{ width: "30%" }}>
                                        <span className="table-value">{row.title}</span>
                                    </TableCell>
                                    <TableCell align="center" className="table-cell" component="div">
                                        <span className="table-value">{row.createdAt}</span>
                                    </TableCell>
                                    <TableCell align="center" className="table-cell" component="div">
                                        <span className="table-value">{row.updatedAt}</span>
                                    </TableCell>
                                    <TableCell align="right" className="table-cell" component="div">
                                        <span className="table-value" style={{ padding: "5px", justifyContent: "flex-end" }}>
                                            <IconButton onClick={() => handleEdit(row.id)}>
                                                <Edit />
                                            </IconButton>
                                            <IconButton onClick={() => setConfirmModal(row.id)}>
                                                <DeleteOutline />
                                            </IconButton>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={totalItems}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <ConfirmationModal
                description="Are you sure to delete this Item ?"
                handleClose={handleClose}
                loading={isPending}
                open={Boolean(confirmModalId)}
                onConfirm={handleDelete}
            />
        </TableWrapperStyle>
    );
}
