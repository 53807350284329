import { Data } from "./type";

import { HeadCell } from "@/components/app/EnhancedTableHeader/type";

const headCells: HeadCell<Data>[] = [
    {
        id: "id",
        numeric: true,
        disablePadding: true,
        label: "ID",
    },
    {
        id: "updatedAt",
        label: "Payment Date",
        numeric: false,
        disablePadding: true,
    },
    {
        id: "amount",
        label: "Amount Paid",
        numeric: false,
        disablePadding: true,
    },
    {
        id: "method",
        label: "Payment Method",
        numeric: false,
        disablePadding: true,
    },
    {
        id: "status",
        label: "Transaction Status",
        numeric: false,
        disablePadding: true,
    },
    {
        id: "source",
        label: "Purchase Source ",
        numeric: false,
        disablePadding: true,
    },
    {
        id: "planName",
        label: "Plan Name",
        numeric: false,
        disablePadding: true,
    },
];

export default headCells;
