import { object, string, number } from "yup";

export const updateCustomerValidationSchema = object().shape({
    firstName: string().required("First Name is required"),
    lastName: string().required("Last Name is required"),
    email: string().email().required("Email is required"),
    countryCode: string().when("phone", {
        is: (value: String) => value !== "",
        then: () => number().nonNullable().required("You Must Enter Country Code if you enter Phone Number"),
    }),
    phone: string(),
});
