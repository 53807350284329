import { useCallback, useMemo, useState } from "react";

import PublisherTable from "./table";
import PublisherStyle from "./style";
import { createData } from "./helper";

import { IPublisherListReqParams } from "@/api/types/publisher";
import { usePublisherList } from "@/api/hooks/publisher";

export default function Publishers() {
    const [params, setParams] = useState<IPublisherListReqParams>({
        search: "",
        skip: 0,
        take: 5,
    });

    const { data: publishers, isLoading, isFetching } = usePublisherList({ skip: params.skip, take: params.take });

    const totalItems = publishers?.data.pagination.totalItems;

    const handleChange = useCallback(({ page, rowsPerPage }: { page?: number, rowsPerPage?: number }) => {
        setParams((prev) => {
            return {
                ...prev,
                skip: Number(page) * Number(rowsPerPage),
                take: rowsPerPage || 10,
            };
        });
    }, []);

    const PublisherData = useMemo(
        () => (publishers ? publishers.data.items.map((item) => createData(item)) : []),
        [publishers]
    );

    return (
        <PublisherStyle>
            <PublisherTable
                data={PublisherData}
                isFetching={isFetching}
                isLoading={isLoading}
                page={Number(params.skip) / Number(params.take)}
                rowsPerPage={Number(params.take)}
                totalItems={totalItems || 0}
                onChange={handleChange}
            />
        </PublisherStyle>
    );
}
