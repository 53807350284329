import { Box, Button, Divider, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

import CreatePlanForm, { ICreatePlanForm } from "../partials/form";

import CreatePlanStyle from "./style";

import { useCreatePlan } from "@/api/hooks/usePlans";
import { AvailableCurrency } from "@/api/types";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import ROUTE_CONSTANTS from "@/constants/route-constants";

export default function CreatePlan() {
    const { mutate: createPlan } = useCreatePlan();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const createAccess = useCheckPermissionAccess("plans", "Create");

    const handleSubmit = (formData: ICreatePlanForm) => {
        const { en_title, ar_title, en_description, ar_description } = formData;

        if (createAccess) {
            createPlan(
                {
                    params: { currency: formData.currency as AvailableCurrency },
                    body: {
                        ...formData,
                        ml_title: { en: en_title, ar: ar_title },
                        ml_description: { en: en_description, ar: ar_description },
                    },
                },
                {
                    onSuccess: () => {
                        navigate(-1);
                    },
                }
            );
        } else {
            enqueueSnackbar("You don't have the correct rights to Create a new plan.", { variant: "warning" });
        }
    };

    return (
        <CreatePlanStyle>
            <Box className="plans-header">
                <Typography className="main-title">Create Plans</Typography>
                <Button component={Link} to={ROUTE_CONSTANTS.SETTINGS.PLANS.ROOT.ABSOLUTE}>
                    Back To Plan List
                </Button>
            </Box>
            <Divider />
            <Box className="form">
                <CreatePlanForm onSubmit={handleSubmit} />
            </Box>
        </CreatePlanStyle>
    );
}
