import { API_ROUTES } from "@/constants/api-routes";
import AxiosInstance from "@/api";
import { IAuthenticatedUser } from "@/api/types/auth";
import { IAxiosResponse } from "@/types/axios-response";
import { ITableParams, ITableResponse } from "@/types/table";
import { IUserActivationPayload, IUserCreatePayload, IUserListResponse, IUserUpdatePayload } from "@/api/types/user";
import {
    IUserFilterParams,
    IUserAccountResponse,
    IUserAccountUpdateBody,
    IUserAccountUpdateResponse,
    IGetUserResponse,
    IGetUsersResponse,
    IUpdateUserBody,
    IUpdateUserResponse,
    IDeleteUserResponse,
    ICreateUserBody,
    ICreateUserResponse,
    IActivateUserBody,
    IActivateUserResponse,
} from "@/api/types/user";
import { serializeObject } from "@/utils/serializer";

export const GetUserAccount = async () => {
    const result = await AxiosInstance.get<IUserAccountResponse>(API_ROUTES.USER.PROFILE);

    return result.data;
};

export const UpdateUserAccount = async (id: number, body: IUserAccountUpdateBody) => {
    const result = await AxiosInstance.put<IUserAccountUpdateResponse>(API_ROUTES.USER.UPDATE(id), body);

    return result.data;
};

export const GetUserById = async (id: number) => {
    const result = await AxiosInstance.get<IGetUserResponse>(API_ROUTES.USER.SHOW(id));

    return result.data;
};

export const GetUsers = async (params: IUserFilterParams) => {
    const result = await AxiosInstance.get<IGetUsersResponse>(`${API_ROUTES.USER.LIST}?${serializeObject(params)}`);

    return result.data;
};

export const UpdateUser = async (id: number, body: IUpdateUserBody) => {
    const result = await AxiosInstance.put<IUpdateUserResponse>(API_ROUTES.USER.UPDATE(id), body);

    return result.data;
};
export const UpdateUserActivation = async (id: number, body: IActivateUserBody) => {
    const result = await AxiosInstance.put<IActivateUserResponse>(API_ROUTES.USER.UPDATE(id), body);

    return result.data;
};

export const DeleteUser = async (id: number) => {
    const result = await AxiosInstance.delete<IDeleteUserResponse>(API_ROUTES.USER.DELETE(id));

    return result.data;
};

export const CreateUser = async (body: ICreateUserBody) => {
    const result = await AxiosInstance.post<ICreateUserResponse>(API_ROUTES.USER.CREATE, body);

    return result.data;
};

export const userService = {
    profile() {
        return AxiosInstance.get<IAxiosResponse<IAuthenticatedUser>>(API_ROUTES.USER.PROFILE);
    },
    list(params?: ITableParams) {
        return AxiosInstance.post<IAxiosResponse<ITableResponse<IUserListResponse>>>(API_ROUTES.USER.LIST, params);
    },
    create(data: IUserCreatePayload) {
        return AxiosInstance.post<IAxiosResponse>(API_ROUTES.USER.CREATE, data);
    },
    update(id: number, data: IUserUpdatePayload) {
        return AxiosInstance.put<IAxiosResponse>(API_ROUTES.USER.UPDATE(id), data);
    },
    delete(id: number) {
        return AxiosInstance.delete<IAxiosResponse>(API_ROUTES.USER.DELETE(id));
    },
    show(id: number) {
        return AxiosInstance.get<IAxiosResponse<IUserListResponse>>(API_ROUTES.USER.SHOW(id));
    },
    activation(id: number, data: IUserActivationPayload) {
        return AxiosInstance.put<IAxiosResponse>(API_ROUTES.USER.ACTIVATION(id), data);
    },
};
