import { styled } from "@mui/material";
import { grey } from "@mui/material/colors";

export default styled("div")(
    ({ theme }) => `

    .center {
        width: 100%;
        margin:0 auto;
        .paper {
            padding: ${theme.spacing(2)};
        }
    }
`
);

export const ShowPublishersStyled = styled("div")`
    .title {
        margin-bottom: 1em;
        font-weight: bold;
        border-bottom:1px solid ${({ theme }) => theme.palette.grey[200]}
    }

    .no-items-to-show {
        margin: 1em 0;
    }

    .publisher-list {
        .publisher-item {
            width: 100%;
            display: flex;
            align-items:center;
            justify-content: space-between;
            margin-bottom: 10px;
            padding: 5px 15px;
            word-wrap: break-word;
            border-radius: ${({ theme }) => theme.shape.borderRadius}px;
            background: ${({ theme }) => (theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[800])};
            border-left: 5px solid ${({ theme }) => theme.palette.primary.main};

            .publisher-title {
                width: 100%;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
            }
        }
    }
`;
