import { useCallback, useState } from "react";

import ShowCountriesStyle from "./style";
import ShowCountriesTable from "./table";

import { useGetCountries } from "@/api/hooks/useCountries";
import { IUserFilterParams } from "@/api/types/user";

export default function ShowCountries() {
    const [params, setParams] = useState<IUserFilterParams>({
        search: "",
        skip: 0,
        take: 10,
        filter: {
            role: null,
            active: null,
            emailVerified: null,
        },
    });

    const { data: countriesData, isFetching, isLoading } = useGetCountries(params);

    const totalItems = countriesData?.data?.pagination?.totalItems;
    const countries = countriesData?.data.items;

    const handleChange = useCallback(({ page, rowsPerPage }: { page?: number, rowsPerPage?: number }) => {
        setParams((prev) => {
            return {
                ...prev,
                skip: Number(page) * Number(rowsPerPage),
                take: rowsPerPage || 10,
            };
        });
    }, []);

    return (
        <ShowCountriesStyle className="countries-wrapper">
            <h1>Countries </h1>
            <ShowCountriesTable
                isFetching={isFetching}
                isLoading={isLoading}
                list={countries || []}
                page={Number(params.skip) / Number(params.take)}
                rowsPerPage={Number(params.take)}
                totalItems={totalItems || 0}
                onChange={handleChange}
            />
        </ShowCountriesStyle>
    );
}
