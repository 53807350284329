import { Box, Button, TextField, SelectChangeEvent, Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { FilterList, FilterListOff } from "@mui/icons-material";
import { useState } from "react";

import { CustomerFiltersStyle } from "./style";

interface IDefaultValues {
    search: string;
    active: number | null;
    emailVerified: number | null;
    hasSubscription: number | null;
}

export const defaultValues: IDefaultValues = {
    search: "",
    active: null,
    emailVerified: null,
    hasSubscription: null,
};

// eslint-disable-next-line no-unused-vars
export default function GenreFilters({ onApply }: { onApply: (data: typeof defaultValues) => void }) {
    const [fields, setFields] = useState(defaultValues);
    const [filterActivated, setFilterActivated] = useState<boolean>(false);

    const handleApply = () => onApply && onApply(fields);
    const toggleFilters = () => setFilterActivated((prev) => !prev);
    const handleReset = () => {
        setFields(defaultValues);
        if (onApply) onApply(defaultValues);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
        const name = event.target.name;
        const value = event.target.value;

        setFields((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    return (
        <CustomerFiltersStyle>
            <Box className="filter-wrapper">
                {filterActivated && (
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item lg={3} md={4} xs={12}>
                            <TextField
                                fullWidth
                                className="input field "
                                label="Search"
                                name="search"
                                placeholder="Enter some text"
                                size="small"
                                value={fields.search}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item lg={2} md={4} xs={12}>
                            <FormControl fullWidth className="select field" size="small">
                                <InputLabel id="select-label">Status</InputLabel>
                                <Select
                                    id="select"
                                    label="status"
                                    labelId="select-label"
                                    name="active"
                                    value={String(fields.active)}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={null as any}>All</MenuItem>
                                    <MenuItem value={1 as any}>Active </MenuItem>
                                    <MenuItem value={0 as any}>Deactivate</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={2} md={4} xs={12}>
                            <FormControl fullWidth className="select field" size="small">
                                <InputLabel id="email-label">Email Confirm</InputLabel>
                                <Select
                                    id="emailVerified"
                                    label="Email Confirmed"
                                    labelId="email-label"
                                    name="emailVerified"
                                    value={String(fields.emailVerified)}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={null as any}> All</MenuItem>
                                    <MenuItem value={1 as any}> Confirmed </MenuItem>
                                    <MenuItem value={0 as any}> Not Confirmed </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={2} md={4} xs={12}>
                            <FormControl fullWidth className="select field" size="small">
                                <InputLabel id="email-label">Has Subscription</InputLabel>
                                <Select
                                    id="hasSubscription"
                                    label="Has Subscription"
                                    labelId="hasSubscription-label"
                                    name="hasSubscription"
                                    value={String(fields.emailVerified)}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={null as any}> All</MenuItem>
                                    <MenuItem value={1 as any}> Subscribed </MenuItem>
                                    <MenuItem value={0 as any}> Not Subscribed </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} xs={12}>
                            <Box className="actions">
                                <Button className="action-btn" variant="contained" onClick={handleApply}>
                                    Apply
                                </Button>
                                <Button className="action-btn" variant="contained" onClick={handleReset}>
                                    reset
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Box>
            <Button
                className="filter-btn"
                startIcon={filterActivated ? <FilterListOff /> : <FilterList />}
                variant="contained"
                onClick={toggleFilters}
            >
                Filters
            </Button>
        </CustomerFiltersStyle>
    );
}
