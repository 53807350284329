import { Data } from "./types";

import { HeadCell } from "@/components/app/EnhancedTableHeader/type";

const headCells: HeadCell<Data>[] = [
    {
        id: "id",
        numeric: true,
        disablePadding: true,
        label: "ID",
    },
    {
        id: "firstName",
        label: "First Name",
        numeric: false,
        disablePadding: true,
    },
    {
        id: "lastName",
        label: "Last Name",
        numeric: false,
        disablePadding: true,
    },
    {
        id: "hasSubscription",
        label: "Subscription",
        numeric: false,
        disablePadding: true,
    },
    {
        id: "email",
        label: "Email",
        numeric: false,
        disablePadding: true,
    },
    {
        id: "updatedAt",
        label: "Updated At",
        numeric: false,
        disablePadding: true,
    },
];

export default headCells;
