import { useCallback, useMemo, useState } from "react";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import PageStyle from "../style";
import { createData } from "../helper";

import UIsFilters, { defaultValues } from "./filters";
import HomePageUITable from "./table";

import { FilterBody } from "@/api/types";
import { useGetUIs } from "@/api/hooks/useHomeUI";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import ROUTE_CONSTANTS from "@/constants/route-constants";

const defaultParams: FilterBody = {
    search: "",
    skip: 0,
    take: 5,
    filter: {
        category: "",
    },
};

export default function HomePageUi() {
    const [params, setParams] = useState<FilterBody>(defaultParams);

    const { data: UIs, isLoading, isFetching } = useGetUIs(params);

    const createAccess = useCheckPermissionAccess("home_ui", "Create");

    const totalItems = UIs?.data.pagination.totalItems;

    const handleChange = useCallback(({ page, rowsPerPage }: { page?: number, rowsPerPage?: number }) => {
        setParams((prev) => {
            return {
                ...prev,
                skip: Number(page) * Number(rowsPerPage),
                take: rowsPerPage || 10,
            };
        });
    }, []);

    const handleFilterOnApply = useCallback((filterData: typeof defaultValues) => {
        const { search, category } = filterData;

        setParams((prev) => {
            return {
                ...prev,
                search: String(search) || "",
                filter: {
                    category,
                },
            };
        });
    }, []);

    const UIsData = useMemo(() => (UIs ? UIs.data.items.map((item) => createData(item)) : []), [UIs]);

    return (
        <PageStyle>
            <DashboardHeader
                title="UIs"
                titleSuffix={
                    createAccess && (
                        <Button
                            color="primary"
                            component={Link}
                            to={ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.ADD_NEW.ROOT.ABSOLUTE}
                            variant="contained"
                        >
                            Add New UIs
                        </Button>
                    )
                }
            >
                <Typography component="p">You can edit their names and role, and reset their passwords.</Typography>
                <Typography component="p">
                    We will send login instructions to their email after the password has been reset.
                </Typography>
            </DashboardHeader>

            <DashboardContent>
                <UIsFilters onApply={handleFilterOnApply} />
                <HomePageUITable
                    data={UIsData}
                    isFetching={isFetching}
                    isLoading={isLoading}
                    page={Number(params.skip) / Number(params.take)}
                    rowsPerPage={Number(params.take)}
                    totalItems={totalItems || 0}
                    onChange={handleChange}
                />
            </DashboardContent>
        </PageStyle>
    );
}
