import { ModalProps, Typography, Button } from "@mui/material";

import ConfirmModalStyle, { ModalHeaderStyled, ModalContentStyled, ModalFooterStyled } from "./styled";

import CompoundModal from "@/components/app/compoundModal";

export interface IConfirmationModalProps extends Omit<ModalProps, "children"> {
    title?: string;
    loading: boolean;
    description: string;
    onConfirm: () => void;
    handleClose: () => void;
}

// onClose type in ModalProps is different than onClose for IConfirmationModalProps

export default function ConfirmationModal({
    title = "Delete Confirmation",
    description,
    loading,
    onConfirm,
    handleClose,
    ...restProps
}: IConfirmationModalProps) {
    return (
        <CompoundModal {...restProps}>
            <ConfirmModalStyle>
                <CompoundModal.Header>
                    <ModalHeaderStyled>
                        <Typography>{title}</Typography>
                    </ModalHeaderStyled>
                </CompoundModal.Header>
                <CompoundModal.Content>
                    <ModalContentStyled>{description}</ModalContentStyled>
                </CompoundModal.Content>
                <CompoundModal.Footer>
                    <ModalFooterStyled>
                        <Button color="error" disabled={loading} variant="contained" onClick={onConfirm}>
                            Yes, I&apos;m Sure
                        </Button>
                        <Button color="primary" disabled={loading} onClick={handleClose}>
                            Cancel
                        </Button>
                    </ModalFooterStyled>
                </CompoundModal.Footer>
            </ConfirmModalStyle>
        </CompoundModal>
    );
}
