import styled from "styled-components";
import { Box, Button, FormControl, Grid, TextField, Select } from "@mui/material";

export const FiltersContainer = styled(Box)`
    margin-bottom:16px;
    margin-top:16px;
    border-radius: 8px;
`;

export const FilterSelect = styled(Select)`
`;

export const ButtonContainer = styled(Box)`
    margin-top: 16px;
    display: flex;
`;

export const ButtonStyled = styled(Button)`
    margin-right: 8px; /* Add margin between buttons */
    
    &:last-child {
        margin-right: 0; /* Remove margin from the last button */
    }
`;

export const GridContainer = styled(Grid)`
    display: grid;
    grid-template-columns: 21% repeat(auto-fit, minmax(14%, 1fr));
    gap:8px;
    align-items: center;
`;

export const SearchInput = styled(TextField)`
    width:100%
`;

export const StyledFormControl = styled(FormControl)`
    margin-top:8px;
`;
