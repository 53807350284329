import axiosInstance from "@/api";
import { API_ROUTES } from "@/constants/api-routes";
import { IPaymentHistoryParams, IPaymentHistoryResponse } from "@/api/types/payments";
import { serializeObject } from "@/utils/serializer";

export const GetPayments = async (params: Partial<IPaymentHistoryParams> = {}) => {
    const queryString = Object.keys(params).length > 0 ? `?${serializeObject(params)}` : "";

    const result = await axiosInstance.get<IPaymentHistoryResponse>(`${API_ROUTES.PAYMENTS.GET_HISTORY}${queryString}`);

    return result.data;
};
