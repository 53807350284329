import dayjs from "dayjs";

import { Data } from "./table/types";

import { Order } from "@/utils/table-utils";
import { IUserAccount } from "@/api/types/user";

export function createData(data: IUserAccount): Data {
    const { id, role, email, state, lastName, firstName, updatedAt, emailVerified, active } = data;

    return {
        id,
        role,
        email,
        state,
        lastName,
        firstName,
        emailVerified,
        active,
        updatedAt: dayjs(updatedAt).format("YYYY-MM-DD HH:mm"),
    };
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }

    return 0;
}

export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
    // eslint-disable-next-line no-unused-vars
): (a: { [key in Key]: number | string | boolean }, b: { [key in Key]: number | string | boolean }) => number {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
