import { Data } from "./types";

import { HeadCell } from "@/components/app/EnhancedTableHeader/type";

const headCells: HeadCell<Data>[] = [
    {
        id: "id",
        numeric: true,
        disablePadding: true,
        label: "ID",
    },
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "UI Name",
    },

    {
        id: "category",
        numeric: false,
        disablePadding: true,
        label: "Category",
    },
    {
        id: "updatedAt",
        numeric: false,
        disablePadding: true,
        label: "Updated At",
    },
    {
        id: "isActive",
        numeric: false,
        disablePadding: true,
        label: "Is Active",
    },
];

export default headCells;
