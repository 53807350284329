export function serializeObject(obj: Record<string, any>, parentKey: string = ""): string {
    const queryString: string[] = [];

    for (const [key, value] of Object.entries(obj)) {
        // Use square bracket notation for nested keys
        const fullKey = parentKey ? `${parentKey}[${key}]` : key;

        if (value === null || value === undefined || value === "") {
            // Skip null, undefined, or empty values
            continue;
        } else if (typeof value === "object" && !Array.isArray(value)) {
            // Recursively serialize nested objects
            const nested = serializeObject(value, fullKey);

            if (nested) {
                queryString.push(nested);
            }
        } else {
            // Encode primitive values
            queryString.push(`${encodeURIComponent(fullKey)}=${encodeURIComponent(String(value))}`);
        }
    }

    return queryString.join("&");
}
