import withCheckPermissions from "@/layouts/GeneralLayoutWrapper/withCheckPermissions";
import TagList from "@/pages/ContentManagement/Tag";
import TagForm from "@/pages/ContentManagement/Tag/AddUpdate";
import GenreList from "@/pages/ContentManagement/Genre";
import GenreForm from "@/pages/ContentManagement/Genre/AddUpdate";
import ContentList from "@/pages/ContentManagement/Content";
import AddMovieFrom from "@/pages/ContentManagement/Content/AddMovie";
import AddSeries from "@/pages/ContentManagement/Content/AddSeries";
import ContentShow from "@/pages/ContentManagement/Content/Show";
import Publisher from "@/pages/ContentManagement/publisher";
import PublisherForm from "@/pages/ContentManagement/publisher/form";

export const ProtectedContentList = withCheckPermissions(ContentList, "content", "List");
export const ProtectedAddMovieFrom = withCheckPermissions(AddMovieFrom, "content", "Create");
export const ProtectedUpdateMovieFrom = withCheckPermissions(AddMovieFrom, "content", "Update");
export const ProtectedAddSeries = withCheckPermissions(AddSeries, "content", "Create");
export const ProtectedUpdateSeries = withCheckPermissions(AddSeries, "content", "Update");
export const ProtectedContentShow = withCheckPermissions(ContentShow, "content", "List");

export const ProtectedTagList = withCheckPermissions(TagList, "tag", "List");
export const ProtectedTagFormAdd = withCheckPermissions(TagForm, "tag", "Create");
export const ProtectedTagFormUpdate = withCheckPermissions(TagForm, "tag", "Update");

export const ProtectedGenreList = withCheckPermissions(GenreList, "genre", "List");
export const ProtectedGenreFormUpdate = withCheckPermissions(GenreForm, "genre", "Update");
export const ProtectedGenreFormAdd = withCheckPermissions(GenreForm, "genre", "Create");

export const ProtectedPublisher = withCheckPermissions(Publisher, "publisher", "List");
export const ProtectedPublisherAdd = withCheckPermissions(PublisherForm, "publisher", "Create");
export const ProtectedPublisherEdit = withCheckPermissions(PublisherForm, "publisher", "Update");
