import dayjs from "dayjs";

import { Data } from "./types";

import { IPublisher } from "@/api/types/publisher";

export function createData(data: IPublisher): Data {
    const { id, updatedAt, createdAt, title } = data;

    return {
        id,
        title,
        createdAt: dayjs(createdAt).format("YYYY-MM-DD HH:mm"),
        updatedAt: dayjs(updatedAt).format("YYYY-MM-DD HH:mm"),
    };
}
