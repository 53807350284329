import { useEffect, useState } from "react";
import { Box, Button, FormHelperText, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Link, useNavigate, useParams } from "react-router-dom";

import { CreatePublisherStyled } from "./style";

import { useCreatePublisher, usePublisherById, useUpdatePublisher } from "@/api/hooks/publisher";
import DashboardHeader from "@/components/app/DashboardHeader";
import ROUTE_CONSTANTS from "@/constants/route-constants";

export default function CreatePublisher() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [input, setInput] = useState<string>("");
    const [error, setError] = useState<string>("");

    const { data } = usePublisherById(Number(id));

    useEffect(() => {
        if (data && data.data) {
            const title = data.data.title;

            setInput(title);
        }
    }, [data]);

    useEffect(() => {
        if (input !== "") setError("");
    }, [input]);

    const { mutate: createPublisher, isPending } = useCreatePublisher();
    const { mutate: updatePublisher, isPending: updatePending } = useUpdatePublisher();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setInput(e.target.value);

    const handleAdd = () => {
        if (input === "") {
            setError("Title is Required");

            return;
        }

        const body = { title: input };

        if (id) {
            updatePublisher(
                { lang: "en", body, id: Number(id) },
                {
                    onSuccess: () => navigate(ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.SHOW_PUBLISHER.ROOT.ABSOLUTE),
                }
            );
        } else {
            createPublisher(
                { lang: "en", body },
                {
                    onSuccess: () => navigate(ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.SHOW_PUBLISHER.ROOT.ABSOLUTE),
                }
            );
        }
    };

    return (
        <CreatePublisherStyled>
            <DashboardHeader
                title=" Create Publisher"
                titleSuffix={
                    <Button component={Link} to={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.SHOW_PUBLISHER.ROOT.ABSOLUTE}>
                        Back to Publishers List
                    </Button>
                }
            />
            <Box className="add-box">
                <TextField
                    fullWidth
                    required
                    label="Publisher title"
                    placeholder="Enter Text"
                    value={input}
                    onChange={handleChange}
                />
                {error && (
                    <FormHelperText error={!!error} sx={{ width: "100%", paddingLeft: "5px" }}>
                        {error}
                    </FormHelperText>
                )}
                <br />
                <LoadingButton
                    fullWidth
                    className="success"
                    disabled={isPending || updatePending}
                    loading={isPending}
                    type="submit"
                    variant="contained"
                    onClick={handleAdd}
                >
                    {id ? "Update" : "Create"}
                </LoadingButton>
            </Box>
        </CreatePublisherStyled>
    );
}
