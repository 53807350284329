import { Box, Button, Stack } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useState } from "react";

import UpdateVideoForm from "./form";
import { IVideoUpdateFormType } from "./type";

import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { videoService } from "@/api/services/video";
import { getAxiosError } from "@/utils/get-axios-error";

export default function AddVideo() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setSubmitLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const createAccess = useCheckPermissionAccess("video", "Create");

    const handleSubmit = async (_val: IVideoUpdateFormType) => {
        try {
            setSubmitLoading(true);
            await videoService.update(Number(id), _val);
            enqueueSnackbar(`Video Updated successfully`, { variant: "success" });
            navigate(ROUTE_CONSTANTS.MEDIA_MANAGEMENT.VIDEOS.ROOT.ABSOLUTE);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";

            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setSubmitLoading(false);
        }
    };

    return (
        <>
            <DashboardHeader
                title="Update Video"
                {...(createAccess
                    ? {
                          titleSuffix: (
                              <Stack direction={"row"} spacing={2}>
                                  <Button
                                      component={Link}
                                      to={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.VIDEOS.SHOW.SHOW_BY_ID(Number(id)).ABSOLUTE}
                                  >
                                      Back To Video Details
                                  </Button>
                              </Stack>
                          ),
                      }
                    : undefined)}
            />
            <DashboardContent>
                <Box sx={{ margin: "0 auto", maxWidth: "500px" }}>
                    <UpdateVideoForm submitLoading={loading} onSubmit={handleSubmit} />
                </Box>
            </DashboardContent>
        </>
    );
}
