import { Link, useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

import TableOfContent from "./table";
import DetailsStyle from "./style";

import { useGetCountryById } from "@/api/hooks/useCountries";
import ROUTE_CONSTANTS from "@/constants/route-constants";

export default function Details() {
    const { id: countryId } = useParams();
    const { data, isFetching, isPending } = useGetCountryById(Number(countryId));

    const result = data?.data;

    if (!result || isPending || isFetching) return <p>Loading ...</p>;

    return (
        <DetailsStyle>
            <Box className="plans-header">
                <Typography className="title">
                    Details of
                    <i>
                        <span style={{ marginInline: "5px" }}>{result.title}</span>
                    </i>
                    Country
                </Typography>
                <Button component={Link} to={ROUTE_CONSTANTS.SETTINGS.COUNTRIES.ROOT.ABSOLUTE}>
                    Back To Countries
                </Button>
            </Box>

            <TableOfContent result={result} />
        </DetailsStyle>
    );
}
