import * as yup from "yup";

export default yup
    .object()
    .shape({
        en_title: yup
            .string()
            .min(2, "English title must be at least 2 characters")
            .max(80, "English title must be at most 80 characters")
            .required("English title is required"),
        ar_title: yup
            .string()
            .min(2, "Arabic title must be at least 2 characters")
            .max(80, "Arabic title must be at most 80 characters")
            .required("Arabic title is required"),
        en_description: yup
            .string()
            .max(600, "English description must be at most 600 characters")
            .required("English description is required"),
        ar_description: yup
            .string()
            .max(600, "Arabic description must be at most 600 characters")
            .required("Arabic description is required"),
        ml_features: yup
            .array(
                yup.object({
                    en: yup.string().required("English feature is required"),
                    ar: yup.string().required("Arabic feature is required"),
                })
            )
            .min(1, "At least 1 feature is required")
            .required("Features are required"),
        googleProductId: yup.string(),
        appleProductId: yup.string(),
        isActive: yup.boolean().required("Active status is required"),
        hasTrial: yup.boolean().required("Trial status is required"),
        currency: yup
            .string()
            .oneOf(["KWD", "BHD", "OMR", "AED", "SAR", "USD"], "Invalid currency")
            .required("Currency is required"),
        price: yup.number().positive("Price must be a positive number").required("Price is required"),
        period: yup.number().required("Period is required"),
        trialPeriod: yup.number().required("Trial period is required"),
        showInWeb: yup.boolean(),
        showInApp: yup.boolean(),
    })
    .test(
        "at-least-one-checked",
        "At least one of 'Show in Web' or 'Show in App' must be selected",
        (values) => values.showInWeb || values.showInApp
    );
