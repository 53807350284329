import { styled } from "@mui/material";

export const DashboardHeaderStyled = styled("div")`
    margin-bottom: ${({ theme }) => theme.spacing(4)};
    display: flex;
    align-items: center;
`;

export const DashboardHeaderTitleStyled = styled("div")`
    display: flex;
    flex-direction: column;
    
    h1 {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 0.5em;
    }

    p, .p {
        font-size: 14px;
        color: ${({ theme }) => (theme.palette.mode === "light" ? "#444" : "#dedede")}
    }
`;

export const DashboardHeaderSuffixStyled = styled("div")`
    margin-left: auto;
`;
