import React from "react";

import { GridCardActionsStyled, GridCardImageStyled, GridCardStyled, GridCardTitleStyled } from "./styled";

interface Props {
    image?: string;
    title: string;
    actions: React.ReactNode;
}
const GridCard: React.FC<Props> = ({ image, title, actions }) => {
    return (
        <GridCardStyled className="grid-card-style">
            <GridCardImageStyled className="image">
                {image ? <img alt={title} src={image} /> : null}
                <GridCardActionsStyled className="action">{actions}</GridCardActionsStyled>
            </GridCardImageStyled>
            <GridCardTitleStyled>{title}</GridCardTitleStyled>
        </GridCardStyled>
    );
};

export default GridCard;
