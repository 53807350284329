import { useEffect } from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";

import { IContentCreateFormType } from "./type";
import Styled from "./style";
import Validation from "./validation";

import SubtitleUploader from "@/components/app/subtitleUploader";
import FormSelectVideo from "@/components/kit/FormGenerator/FormSelectVideo";
import FormSelectImage from "@/components/kit/FormGenerator/FormSelectImage";
import FormText from "@/components/kit/FormGenerator/FormText";
import FormTextRtl from "@/components/kit/FormGenerator/FormTextRtl";
import FormTextareaRtl from "@/components/kit/FormGenerator/FormTextareaRtl";
import FormRadio from "@/components/kit/FormGenerator/FormRadio";
import FormSelect from "@/components/kit/FormGenerator/FormSelect";
import FormTextarea from "@/components/kit/FormGenerator/FormTextarea";
import FormMultiSelect from "@/components/kit/FormGenerator/FormMultiSelect";
import { useGenreList } from "@/api/hooks/useGenre";
import { useTags } from "@/api/hooks/useTags";
import { useAgeRange } from "@/api/hooks/useAgeRange";
import { useContentById } from "@/api/hooks/useContents";
import { usePublisherList } from "@/api/hooks/publisher";
import { useGetCountries } from "@/api/hooks/useCountries";

const defaultValues: IContentCreateFormType = {
    mainVideo: 0,
    trailerVideo: 0,
    thumbnailPortraitId: 0,
    thumbnailLandscapeId: 0,
    duration: "",
    intro_duration: "",
    en_title: "",
    slug: "",
    ar_title: "",
    en_description: "",
    ar_description: "",
    sharing_state: 1,
    category: 1,
    genre: [],
    tag: [],
    restrictedIn: [],
    age_range: 0,
    imdb: "",
    subtitle: "",
    publisherId: 0,
};

const body = {
    skip: 0,
    take: 10,
    search: "",
    sort: "",
    filter: "",
};

// eslint-disable-next-line no-unused-vars
export default function AddForm({ onSubmit }: { onSubmit: (formData: IContentCreateFormType) => void }) {
    const { id } = useParams();
    const { data } = useContentById(Number(id));
    const { data: genresList, isSuccess: isSuccessGenre } = useGenreList({ skip: 0, take: 1000 });
    const { tags, isSuccess: isSuccessTags } = useTags();
    const { ageRanges, isSuccess: isSuccessAgeRange } = useAgeRange();
    const { data: publishersData, isSuccess: isSuccessPublisher } = usePublisherList(body);
    const { data: countriesData } = useGetCountries({ take: 100 });

    const countries = countriesData?.data.items;
    const publishers = publishersData?.data.items;

    const submitLoading = false;
    const hasEditId = !!id;

    const content = data?.data.data;

    const {
        control,
        reset,
        handleSubmit,
        watch,
        trigger,
        formState: { errors, isValid },
    } = useForm<IContentCreateFormType>({
        defaultValues,
        mode: "all",
        resolver: yupResolver(Validation),
    });

    // this code is very important - it's like refresh button for react-hook-form, make it update.
    useEffect(() => {
        if (isValid) void trigger();
    }, [trigger, isValid]);

    useEffect(() => {
        if (id) {
            reset(defaultValues);
        }
    }, [reset, id]);

    // check category
    const category = watch("category");
    const videoId = watch("mainVideo");
    const isEntertainment = Number(category) === 2;
    const required = Number(watch("sharing_state")) === 2;

    const Publishers = publishers
        ? publishers.map((publisher) => ({ value: publisher.id, label: publisher.title }))
        : [{ value: 0, label: "None" }];

    const AgeRanges = ageRanges
        ? ageRanges.map((ageRange) => ({ value: ageRange.id, label: ageRange.ml_title.en }))
        : [{ value: 0, label: "None" }];

    // push default value to get rid of warning
    AgeRanges.push({ value: 0, label: "None" });
    Publishers.push({ value: 0, label: "None" });

    const Countries = countries
        ? countries.map((country) => ({ value: country.id, label: country.title }))
        : [{ value: 0, label: "None" }];

    const Genres =
        genresList &&
        genresList.items
            .filter((item) => item.isEntertainment === isEntertainment)
            .map((genre) => ({ value: genre.id, label: genre.ml_title.en }));

    const TagList =
        tags &&
        tags
            .filter((item) => item.isEntertainment === isEntertainment)
            .map((tag) => ({ value: tag.id, label: tag.ml_title.en }));

    // update form data in Edit mode
    useEffect(() => {
        // make sure genre & tags & ageRange are fetched before
        if (content && isSuccessGenre && isSuccessTags && isSuccessAgeRange) {
            const {
                imdb,
                publisher,
                introLength,
                isEntertainment,
                length,
                ageRangeId,
                ml_description,
                ml_name,
                slug,
                status,
                thumbnailLandscape,
                thumbnailPortrait,
                genres,
                tags,
                mainVideo,
                trailerVideo,
                restrictedIn,
            } = content;

            reset({
                imdb: imdb || "",
                age_range: ageRangeId,
                ar_title: ml_name.ar,
                ar_description: ml_description.ar,
                en_title: ml_name.en,
                duration: String(length),
                category: isEntertainment ? 2 : 1,
                en_description: ml_description.en,
                slug,
                sharing_state: status === "Draft" ? 1 : 2,
                intro_duration: String(introLength),
                genre: genres.map((item) => item.id),
                tag: tags.map((item) => item.id),
                restrictedIn,
                thumbnailLandscapeId: thumbnailLandscape?.id,
                thumbnailPortraitId: thumbnailPortrait?.id,
                mainVideo: mainVideo?.id,
                trailerVideo: trailerVideo?.id,
                publisherId: publisher?.id,
            });
        }
    }, [content, reset, isSuccessGenre, isSuccessTags, isSuccessAgeRange, isSuccessPublisher]);

    return (
        <Styled>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="section media">
                    <h3 className="title">Video&rsquo;s Media </h3>
                    <div className="content">
                        <div className="col left-sec">
                            <div className="portrait-box">
                                <Controller
                                    key="thumbnailPortraitId"
                                    control={control}
                                    name="thumbnailPortraitId"
                                    render={({ field }) => (
                                        <FormSelectImage
                                            className="portrait-thumb"
                                            errorMessage={errors["thumbnailPortraitId"] as FieldError}
                                            field={{ ...field }}
                                            label="Thumbnail Portrait"
                                            placeholder="Image File"
                                            props={{ previewImage: content?.thumbnailPortrait?.url, required }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="col right-sec">
                            <div className="landscape-box">
                                <Controller
                                    key="thumbnailLandscapeId"
                                    control={control}
                                    name="thumbnailLandscapeId"
                                    render={({ field }) => (
                                        <FormSelectImage
                                            className="landscape-thumb"
                                            errorMessage={errors["thumbnailLandscapeId"] as FieldError}
                                            field={{ ...field }}
                                            label="Thumbnail Landscape"
                                            placeholder="Image File"
                                            props={{ previewImage: content?.thumbnailLandscape?.url, required }}
                                        />
                                    )}
                                />
                                <div className="video-section">
                                    <Controller
                                        key="mainVideo"
                                        control={control}
                                        name="mainVideo"
                                        render={({ field }) => (
                                            <FormSelectVideo
                                                className="select-video"
                                                errorMessage={errors["mainVideo"] as FieldError}
                                                field={{ ...field }}
                                                label="Select Video"
                                                placeholder="Video File"
                                                props={{ previewImage: content?.mainVideo?.thumbnail?.url, required }}
                                            />
                                        )}
                                    />
                                    <Controller
                                        key="trailerVideo"
                                        control={control}
                                        name="trailerVideo"
                                        render={({ field }) => (
                                            <FormSelectVideo
                                                className="select-trailer"
                                                errorMessage={errors["trailerVideo"] as FieldError}
                                                field={{ ...field }}
                                                label="Select Trailer Video"
                                                placeholder="Video File"
                                                props={{
                                                    previewImage: content?.trailerVideo?.thumbnail?.url,
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section subtitles">
                    <h3 className="title">Video&rsquo;s Subtitles </h3>
                    <div className="content row">
                        {!!videoId ? (
                            <>
                                <SubtitleUploader id={videoId} label="Select English Subtitle" lang="en" />
                                <SubtitleUploader id={videoId} label="Select Arabic Subtitle" lang="ar" />
                            </>
                        ) : (
                            <Typography>In order to upload any subtitle you need to select main video first</Typography>
                        )}
                    </div>
                </div>

                <div className="section info">
                    <h3 className="title">Video&rsquo;s Information </h3>
                    <div className="content grid">
                        <Controller
                            key="en_title"
                            control={control}
                            name="en_title"
                            render={({ field }) => (
                                <FormText
                                    errorMessage={errors["en_title"] as FieldError}
                                    field={{ ...field }}
                                    label="English Content Name "
                                    placeholder="Enter English Text"
                                    props={{ required: true }}
                                />
                            )}
                        />
                        <Controller
                            key="ar_title"
                            control={control}
                            name="ar_title"
                            render={({ field }) => (
                                <FormTextRtl
                                    errorMessage={errors["ar_title"] as FieldError}
                                    field={{ ...field }}
                                    label="Arabic Content Name "
                                    placeholder="Enter Arabic Text"
                                    props={{ required: true }}
                                />
                            )}
                        />
                        <Controller
                            key="en_description"
                            control={control}
                            name="en_description"
                            render={({ field }) => (
                                <FormTextarea
                                    className="textarea"
                                    errorMessage={errors["en_description"] as FieldError}
                                    field={{ ...field }}
                                    label="English Description"
                                    placeholder="Enter English Text"
                                />
                            )}
                        />
                        <Controller
                            key="ar_description"
                            control={control}
                            name="ar_description"
                            render={({ field }) => (
                                <FormTextareaRtl
                                    className="textarea"
                                    errorMessage={errors["ar_description"] as FieldError}
                                    field={{ ...field }}
                                    label="Arabic Description"
                                    placeholder="Enter Arabic Text"
                                />
                            )}
                        />
                    </div>
                    <div className="content row">
                        <Controller
                            key="category"
                            control={control}
                            name="category"
                            render={({ field }) => (
                                <FormRadio
                                    data={[
                                        { value: 1, label: "Education" },
                                        { value: 2, label: "Entertainment" },
                                    ]}
                                    errorMessage={errors["category"] as FieldError}
                                    field={{ ...field }}
                                    label="Category"
                                    placeholder="Select Option"
                                    props={{ required }}
                                />
                            )}
                        />
                    </div>
                    <div className="content grid">
                        <Controller
                            key="publisherId"
                            control={control}
                            name="publisherId"
                            render={({ field }) => (
                                <FormSelect
                                    data={Publishers}
                                    errorMessage={errors["publisherId"] as FieldError}
                                    field={{ ...field }}
                                    label="Publisher"
                                    placeholder="Select Items"
                                    props={{ required }}
                                />
                            )}
                        />
                        <Controller
                            key="age_range"
                            control={control}
                            name="age_range"
                            render={({ field }) => (
                                <FormSelect
                                    data={AgeRanges}
                                    errorMessage={errors["age_range"] as FieldError}
                                    field={{ ...field }}
                                    label="Age Range"
                                    placeholder="Select Items"
                                    props={{ required }}
                                />
                            )}
                        />
                        <Controller
                            key="tag"
                            control={control}
                            name="tag"
                            render={({ field }) => (
                                <FormMultiSelect
                                    data={TagList}
                                    errorMessage={errors["tag"] as FieldError}
                                    field={{ ...field }}
                                    label={`Tag (${Number(category) === 1 ? "Education" : "Entertainment"})`}
                                    placeholder="Select Items"
                                    props={{ required }}
                                />
                            )}
                        />
                        <Controller
                            key="genre"
                            control={control}
                            name="genre"
                            render={({ field }) => (
                                <FormMultiSelect
                                    data={Genres}
                                    errorMessage={errors["genre"] as FieldError}
                                    field={{ ...field }}
                                    label="Genre"
                                    placeholder="Select Items"
                                    props={{ required }}
                                />
                            )}
                        />
                        <Controller
                            key="restrictedIn"
                            control={control}
                            name="restrictedIn"
                            render={({ field }) => (
                                <FormMultiSelect
                                    data={Countries}
                                    errorMessage={errors["restrictedIn"] as FieldError}
                                    field={{ ...field }}
                                    label="Restrictions"
                                    placeholder="Select Countries for Restriction"
                                />
                            )}
                        />
                        <Controller
                            key="slug"
                            control={control}
                            name="slug"
                            render={({ field }) => (
                                <FormText
                                    errorMessage={errors["slug"] as FieldError}
                                    field={{ ...field }}
                                    label="Slug"
                                    placeholder="Slug"
                                />
                            )}
                        />{" "}
                    </div>
                    <div className="content row">
                        <Controller
                            key="sharing_state"
                            control={control}
                            name="sharing_state"
                            render={({ field }) => (
                                <FormRadio
                                    className="sharing_state"
                                    data={[
                                        { value: 1, label: "Draft" },
                                        { value: 2, label: "Publish" },
                                    ]}
                                    errorMessage={errors["sharing_state"] as FieldError}
                                    field={{ ...field, value: Number(field.value) }}
                                    label="Sharing State"
                                    placeholder="Select Option"
                                    props={{ required: true }}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="actions">
                    <LoadingButton
                        className="loading-btn"
                        disabled={!isValid || submitLoading}
                        loading={submitLoading}
                        type="submit"
                        variant="contained"
                    >
                        {hasEditId ? "Update" : "Create"} Content
                    </LoadingButton>
                </div>
            </form>
        </Styled>
    );
}
