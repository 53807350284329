import { styled } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export const FormVideoStyled = styled("div")(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    border: 1px solid ${theme.palette.mode === "dark" ? "#494949" : "#c4c4c4"};
    border-radius: 28px;
    margin-bottom: ${theme.spacing(1)};
    margin-top: ${theme.spacing(2)};
    &:hover {
        border-color: ${theme.palette.mode === "dark" ? "#fff" : "#000"};
    }
    > label {
        font-size: 12px;
        align-self: flex-start;
        margin-left: ${theme.spacing(1)};
        line-height: 24px;
        margin-top: -12px;
        background-color: ${theme.palette.background.default};
        padding: 0 ${theme.spacing(1)};
    }
    > span {
        margin: ${theme.spacing(1)} ${theme.spacing(1.5)} ${theme.spacing(1.5)};
        display: block;
        word-break: break-all;
        font-size: 12px;
        small {
            margin-left: ${theme.spacing(1)};
        }
    }
`
);
export const FormVideoUploadStyled = styled("div")(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing(2)};
    height: 100%;
`
);
export const FormVideoWrapperStyled = styled("div")(
    ({ theme }) => `
    position: relative;
    display: flex; 
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    background: ${theme.palette.mode === "dark" ? "#333" : "#f5f5f5"};
    align-items: center;
    border-radius: 18px;
    margin: ${theme.spacing(1.5)};
    overflow: hidden;
    height: 150px;

    .warning {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        text-align: center;
        padding: ${theme.spacing(1)};
        box-sizing: border-box;
        transform: translate(-50%, -50%);
    }

    span {
        margin-top: ${theme.spacing(1)};
        font-size: 12px;
        font-weight: 600
    }
    small {
        margin-top: ${theme.spacing(0.5)};
        color: #888;
        font-size: 12px;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover
    }
`
);

export const FormVideoInputStyled = styled("input")`
    position: absolute;
    left: 0;
    cursor: pointer;
    right: 0;
    bottom: 0;
    top: 0;
    overflow: hidden;
    opacity: 0;
`;

export const DeleteIconStyled = styled(DeleteIcon)(
    ({ theme }) => `
    position: absolute;
    cursor: pointer;
    right: ${theme.spacing(1)};
    top: ${theme.spacing(1)};
    z-index: 100;
    width: 32px;
    height: 32px;
    padding: ${theme.spacing(0.5)};
    background:  ${theme.palette.mode === "dark" ? "rgba(0,0,0,0.8)" : "rgba(255,255,255,0.8)"};
    border-radius: ${theme.spacing(0.5)};
`
);
