import { Box, Divider, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

import ShowPlans from "./list";
import PlanPageStyle from "./style";

import ROUTE_CONSTANTS from "@/constants/route-constants";
import { usePlansList } from "@/api/hooks/usePlans";

export default function PlanPage() {
    const navigate = useNavigate();

    const { data, isLoading } = usePlansList({ currency: "KWD" });

    if (!data || isLoading) return <Typography>Loading data ...</Typography>;

    const AllPlans = data?.data.items.sort(function (a, b) {
        const keyA = a.isActive,
            keyB = b.isActive;

        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;

        return 0;
    });

    const Web = AllPlans.filter((item) => item.showInWeb);
    const App = AllPlans.filter((item) => item.showInApp);

    const handleClickOpen = () => navigate(ROUTE_CONSTANTS.SETTINGS.PLANS.ADD_NEW.ABSOLUTE);

    return (
        <PlanPageStyle>
            <Box className="plans-header">
                <Typography className="main-title">Plans</Typography>
                <LoadingButton className="episode-btn" type="submit" variant="contained" onClick={handleClickOpen}>
                    Create Plan
                </LoadingButton>
            </Box>
            <br />
            <Box className="plans-header">
                <Typography className="sub-title">Web</Typography>
            </Box>
            <Divider />
            <ShowPlans planList={Web} />
            <br />
            <Box className="plans-header">
                <Typography className="sub-title">Application</Typography>
            </Box>
            <Divider />
            <ShowPlans planList={App} />
        </PlanPageStyle>
    );
}
