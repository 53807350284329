import HomePage from "@/pages/AppUI/HomePage/Show/index";
import HomeUiForm from "@/pages/AppUI/HomePage/AddUpdate";
import HomeUiSection from "@/pages/AppUI/HomePage/Section";
import withCheckPermissions from "@/layouts/GeneralLayoutWrapper/withCheckPermissions";

export const ProtectedUIHomePage = withCheckPermissions(HomePage, "ui_home", "List");
export const ProtectedUIFormUpdate = withCheckPermissions(HomeUiForm, "ui_home", "Update");
export const ProtectedUIFormCreate = withCheckPermissions(HomeUiForm, "ui_home", "Create");
export const ProtectedUISectionUpdate = withCheckPermissions(HomeUiSection, "ui_home", "Update");
export const ProtectedUISectionCreate = withCheckPermissions(HomeUiSection, "ui_home", "Create");
